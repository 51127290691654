import { Box, Button, InputLabel, Input, Select, MenuItem } from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const AddCareer = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const history = useNavigate();

    const initialValues = {
        title: "",
        content: "",
        status: ""
    }
    
    const adminSchema = yup.object().shape({
        title: yup.string().required("This field is required"),
        content: yup.string().required("This field is required"),
        status: yup.string().required("This field is required"),
    })

    const handleFormikSubmit = (values) => {
        console.log(values);
        const sendRequest = async () => {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/careers/post-career`, {
                title: values.title,
                content: values.content,
                status: values.status
            }, { withCredentials: true}).catch(err => console.log(err));
            const data = await res.data;
            console.log(data);
            return data;
        }
        sendRequest()
        .then(()=>history("/careers"));
    }

    return <Box m="20px">
        <Box>
            <Header title="ADD A JOB OPENING" subtitle="Add a New Job Opportunity on GTMPM" />    
        </Box>

        <Formik
            onSubmit={handleFormikSubmit}
            initialValues={initialValues}
            validationSchema={adminSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >
                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="title">Job Title</InputLabel>
                                <Input
                                    fullWidth 
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                    name="title"
                                    error={!!touched.title && !!errors.title}
                                />
                            </Box>

                            <Box
                                sx={{ gridColumn: "span 2", backgroundColor: "transparent", position: "relative"}}
                            >
                                <InputLabel id="select"
                                    sx={{
                                        position: "absolute",
                                        zIndex: "1",
                                        marginTop: "2px",
                                        paddingLeft: "10px"
                                    }}
                                >Select Offer Status</InputLabel>
                                <Select
                                    fullWidth
                                    variant="filled"
                                    labelId="select"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.status}
                                    name="status"
                                    error={!!touched.status && !!errors.status}
                                    sx={{ 
                                        backgroundColor: "transparent", 
                                        position: "absolute",
                                        zIndex: "111",
                                        top: "0px",
                                        height: "50px"
                                    }}
                                >
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Close">Close</MenuItem>
                                </Select>
                            </Box>
                            
                            <Box sx={{ gridColumn: "span 4"}}>
                                    <InputLabel id="content">About The Job</InputLabel>
                                <Input
                                    labelid="content"
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    multiline
                                    rows="3"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.content}
                                    name="content"
                                    error={!!touched.content && !!errors.content}
                                    sx={{ gridColumn: "span 2"}}
                                />
                            </Box>
                           
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Publish Job Opening
                            </Button>
                        </Box>
                    </form>
                )
            }
        </Formik>
    </Box>
}
 
export default AddCareer;