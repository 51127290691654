import { Box, IconButton, useTheme, InputBase } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import axios from "axios";

const Topbar = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode =useContext(ColorModeContext);

    let message;
    const handleLogout = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/team/logout`, null, { withCredentials: true })
        .then(res => {
            message = res.data.message;
            console.log(message);
            if(res.status === 200) {
                console.log(message)
                setTimeout(()=>window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/gtmpm-admin`), 300)
            } else {
              console.log(message)
            }
        })
        .catch(err => {
          console.log(err)
        })
    }

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Search box */}
            <Box 
                display="flex" 
                backgroundColor={colors.primary[400]} 
                borderRadius="3px"
            >
                <InputBase sx={{ml:2, flex:1}} placeholder="Search" />
                <IconButton type="button" sx={{}}>
                    <SearchIcon />
                </IconButton>
            </Box>


            {/* Icons */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode} >
                    {theme.palette.mode === "dark" ? (
                        <LightModeOutlinedIcon />
                        ) : 
                        <DarkModeOutlinedIcon />
                    }
                    
                </IconButton>

                <Link to="/subscribers">
                    <IconButton >
                        <NotificationsOutlinedIcon />
                    </IconButton>
                </Link>

                <Link to="/profile">
                    <IconButton >
                        <PersonOutlinedIcon />
                    </IconButton>
                </Link>
                
                <IconButton onClick={handleLogout} >
                    <LogoutOutlinedIcon />
                </IconButton>
                
                
            </Box>
        </Box>
    )
}

export default Topbar;