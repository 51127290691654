import { Box, CardContent, Typography, Card, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/global/Header';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { formatter } from '../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';

const WithdrwalDetails = () => {
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { txId } = useParams();
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/withdrawals/fetch-withdrawal/${txId}`
  );

  const approveWithdrawal = async (id) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/withdrawals/approve-withdrawal/${id}`,
      {
        withCredentials: true,
      }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const declineWithdrawal = async (id) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/withdrawals/decline-withdrawal/${id}`,
      {
        withCredentials: true,
      }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const pendWithdrawal = async (id) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/withdrawals/pend-withdrawal/${id}`,
      {
        withCredentials: true,
      }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  let date;
  if (data) {
    var dateString = new Date(data.date).toString();
    var splittedDateString = dateString.split(' ');
    var day = splittedDateString[0];
    var day2 = splittedDateString[1];
    var month = splittedDateString[2];
    var year = splittedDateString[3];
    date = `${day}, ${day2} ${month}, ${year}`;
  }

  return (
    <Box m="20px">
      <Box>
        <Header
          title={
            data
              ? `Withdrawal of - ${formatter.format(data.amount)} by Marketer; ${data.name}`
              : 'Withdrawal of some funds byt a Marketer'
          }
          subtitle="Here is the Details of the qithdrawal request."
        />
      </Box>

      <Box marginTop="70px" display="flex" justifyContent="center" alignItems="center">
        <Box backgroundColor={colors.primary[400]} width="42%">
          {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
          {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
          {data && (
            <Card
              sx={{
                background: `${colors.primary[400]} !important`,
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 5px 12px 5px #000c02d8 !important;',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '30px',
                }}
              >
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography sx={{ fontSize: '20px' }} variant="h5">
                    By: {data.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: `${colors.grey[300]}`,
                      border: `1px solid ${colors.grey[600]}`,
                      display: 'inline-block',
                      padding: '2px 10px',
                      margin: '3px',
                    }}
                  >
                    Request Date: {date}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                    Amount: {formatter.format(data.amount)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `${colors.primary[500]} !important`,
                  width: '100%',
                  padding: '13px 40px',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Receiver:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.accountName}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Account Number:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.accountNumber}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Bank:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.bankName}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Account Type:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.accountType}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Transaction Status:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.status}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `${colors.primary[400]} !important`,
                  width: '100%',
                  padding: '13px',
                }}
              >
                <br />
                <Box display="flex" justifyContent="space-around" padding="5px">
                  {data.status === 'Pending' ? (
                    <>
                      <Typography
                        onClick={() => {
                          approveWithdrawal(data.id);
                        }}
                        sx={{
                          backgroundColor: `${colors.greenAccent[500]}`,
                          color: colors.grey[900],
                          border: `1px solid ${colors.grey[600]}`,
                          display: 'inline-block',
                          padding: '5px 15px',
                          margin: '3px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        Approve
                      </Typography>

                      <Typography
                        onClick={() => {
                          declineWithdrawal(data.id);
                        }}
                        sx={{
                          backgroundColor: `${colors.redAccent[500]}`,
                          color: colors.grey[900],
                          border: `1px solid ${colors.grey[600]}`,
                          display: 'inline-block',
                          padding: '5px 15px',
                          margin: '3px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        Decline
                      </Typography>
                    </>
                  ) : data.status === 'Completed' ? (
                    <>
                      <Typography
                        onClick={() => {
                          pendWithdrawal(data.id);
                        }}
                        sx={{
                          backgroundColor: `${colors.blueAccent[500]}`,
                          color: colors.grey[900],
                          border: `1px solid ${colors.grey[600]}`,
                          display: 'inline-block',
                          padding: '5px 15px',
                          margin: '3px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        Pend It
                      </Typography>

                      <Typography
                        onClick={() => {
                          declineWithdrawal(data.id);
                        }}
                        sx={{
                          backgroundColor: `${colors.redAccent[500]}`,
                          color: colors.grey[900],
                          border: `1px solid ${colors.grey[600]}`,
                          display: 'inline-block',
                          padding: '5px 15px',
                          margin: '3px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        Decline
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          color: colors.greenAccent[500],
                          border: `1px solid ${colors.grey[600]}`,
                          display: 'inline-block',
                          padding: '5px 15px',
                          margin: '3px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        Transaction was declined and Marketer's pending balance has been updated.
                        The marketer can reinitiate the transaction.
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default WithdrwalDetails;
