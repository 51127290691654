import { Box, Button, Select, MenuItem, InputLabel, Input } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";
import { useState } from 'react';

import {Formik} from "formik";
import * as yup from "yup";

import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const AddProject = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const history = useNavigate();
    const [images, setImages] = useState();
    const [projectCoverImage, setProjectCoverImage] = useState();

    const initialValues = {
        projectTitle: "",
        projectDetails: "",
        status: "",
    }
    
    const validationSchema = yup.object().shape({
        projectTitle: yup.string().required("Project Title is required"),
        projectDetails: yup.string().required("Project Details is required"),
        status: yup.string().required("Select Project Status"),
    })
    

    const handleImagesChange = (e) => { 
        setImages(e.target.files);
    }
    const handleProjectCoverImageChange = (e) => { 
        setProjectCoverImage(e.target.files[0]);
    }

    const handleSubmit = (values) => {
        const formData = new FormData();
        for(let i=0; i<images.length; i++) {
            formData.append(`images`, images[i])
        }
        formData.append('projectCoverImage', projectCoverImage);
        formData.append('projectTitle', values.projectTitle);
        formData.append('projectDetails', values.projectDetails);
        formData.append('status', values.status);

        console.log(...formData);
        axios.post(`${process.env.REACT_APP_API_URL}/projects/post-project`, formData, { withCredentials: true})
        .then(res => {
            if(res.status === 200) {
                console.log("Successfully published data")
            } else {
                console.log("Failed")
            }
        })
        .then(()=>history("/projects"))
        .catch(err => console.log(err))
    }


    return <Box m="20px">
        <Box>
            <Header title="ADD NEW PROJECT" subtitle="Add a New Project Details to the Website" />    
        </Box>
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >   

                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="projectDetails">Project Title</InputLabel>
                                <Input
                                    fullWidth 
                                    type="text"
                                    onChange={handleChange}
                                    name="projectTitle"
                                    // required
                                    value={values.projectTitle}
                                    error={!!touched.projectTitle && !!errors.projectTitle}
                                    helperText={touched.projectTitle && errors.projectTitle}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            
                            <Box sx={{ gridColumn: "span 2", backgroundColor: "transparent", position: "relative"}} >
                                <InputLabel id="select"
                                    sx={{
                                        position: "absolute",
                                        zIndex: "1",
                                        marginTop: "2px",
                                    }}
                                >Select Status</InputLabel>
                                <Select
                                    fullWidth
                                    variant="filled"
                                    labelId="select"
                                    onChange={handleChange}
                                    name="status"
                                    sx={{ 
                                        backgroundColor: "transparent", 
                                        position: "absolute",
                                        zIndex: "111",
                                        top: "0px",
                                        height: "50px"
                                    }}
                                    // required
                                    error={!!touched.status && !!errors.status}
                                    helperText={touched.status && errors.status}
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value="Pending">Pending</MenuItem>
                                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                </Select>
                            </Box>
                            
                            <Box sx={{ gridColumn: "span 4"}}>
                                    <InputLabel id="projectDetails">Project Details</InputLabel>
                                <Input
                                    labelid="projectDetails"
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    multiline
                                    rows="3"
                                    onChange={handleChange}
                                    name="projectDetails"
                                    sx={{ gridColumn: "span 2"}}
                                    // required
                                    value={values.projectDetails}
                                    error={!!touched.projectDetails && !!errors.projectDetails}
                                    helperText={touched.status && errors.status}
                                    onBlur={handleBlur}
                                />
                            </Box>

                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="projectDetails">Cover Image</InputLabel>
                                <Input
                                    fullWidth
                                    variant="filled"
                                    type="file"
                                    label="Cover Image"
                                    placeholder="Cover Image"
                                    onChange={handleProjectCoverImageChange}
                                    name="projectCoverImage"
                                    sx={{ gridColumn: "span 2"}}
                                    required
                                />
                            </Box>

                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="projectDetails" sx={{paddingBottom:"7px"}}>More Project Images</InputLabel>
                                <input 
                                    type="file" 
                                    name="images" 
                                    onChange={handleImagesChange} 
                                    multiple required />
                            </Box>
                            
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Publish Project Data
                            </Button>
                        </Box>
                    </form>

                )
            }
        </Formik>
    </Box>
}
 
export default AddProject;