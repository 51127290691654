import { Typography, Box, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import Header from "../../components/global/Header";
import styled from "styled-components";

import axios from "axios";
import useFetchCredential from "../../hooks/useFetchCredential";

axios.defaults.withCredentials = true;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/team/get-team`);

    const deleteTeamMember = (teamID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/team/delete-team/${teamID}`, { withCredentials: true})
        .then(window.location.reload());;
    };

    const columns = [
        {
            field: "coverImage",
            headerName: "",
            flex: 0.6,
            renderCell: ({ row : { coverImage }}) => {
                return (
                    <Box>
                        <img 
                            height="30px"
                            width="30px"
                            src={`${process.env.REACT_APP_IMG_BASEURL}/team/${coverImage}`} 
                            alt="" 
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                )
            }
        },
        {
            field: "fullName",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "post",
            headerName: "Position",
            headerAlign: "left",
            flex: 1,
            align: "left",
        },
        {
            field: "contact",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "access",
            headerName: "Access Level",
            flex: 1.3,
            renderCell: ({ row : { access }}) => {
                return (
                    <Box
                        width="60%"
                        m="0 0 0 0"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            access === "Administrator" ? colors.blueAccent[400]
                            : access === "Software Developer" ? colors.greenAccent[600]
                            : colors.redAccent[700]
                        }
                        borderRadius = "4px"
                    >
                        {access === "Administrator" && <AdminPanelSettingsOutlinedIcon />}
                        {access === "Software Developer" && <SecurityOutlinedIcon />}
                        {access === "Board Member" && <LockOpenOutlinedIcon />}
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {access}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            headerName: "Actions",
            flex: 0.7,
            align: "left",
            // field: "id",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`/team-member-profile/${params.row.teamID}`}>   
                            <IconButton>
                                <RemoveRedEyeOutlined sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <Link to={`/edit-admin/${params.row.teamID}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteTeamMember(params.row.teamID)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="TEAM" subtitle="Managing the Team Members"  />
            <Box>
                <StyledLink
                    to="/add-team-member"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <PersonAddOutlined sx={{ mr: "10px" }} />
                        Add New Team Member
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="40px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                }
            }}
        >   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && <DataGrid rows={data} columns={columns} />}
            
        </Box>
    </Box>
}
 
export default Team;