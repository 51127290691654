import { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ShopOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';

import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import logo from '../../assets/img/logo.png';
import MenuItemComp from '../../components/global/sidebar/MenuItemComp';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import axios from 'axios';
import useFetchDataCredential from '../../hooks/useFetchDataCredential';

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');

  const { data, loading, error } = useFetchDataCredential(
    `${process.env.REACT_APP_API_URL}/team/dashboard`
  );

  let message;
  const handleLogout = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/team/logout`, null, { withCredentials: true })
      .then((res) => {
        message = res.data.message;
        console.log(message);
        if (res.status === 200) {
          console.log(message);
          setTimeout(
            () => window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/gtmpm-admin`),
            300
          );
        } else {
          console.log(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#686dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      {data && (
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: '10px 0 20px 0',
                color: colors.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                  <img
                    alt="admin avatar"
                    height="50px"
                    width="100px"
                    src={logo}
                    style={{ cursor: 'pointer' }}
                  />
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {/* USER */}
            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="admin avatar"
                    height="100px"
                    width="100px"
                    src={`${process.env.REACT_APP_IMG_BASEURL}/team/${data.coverImage}`}
                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                  />
                </Box>

                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{
                      m: '10px 0 0 0',
                    }}
                  >
                    {data.fullName}
                  </Typography>
                  <Typography variant="h5" color={colors.grey[500]}>
                    {data.post}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Menu Items */}
            <Box paddingLeft={isCollapsed ? undefined : '10%'}>
              <MenuItemComp
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Urgent Requests
              </Typography>
              <MenuItemComp
                title="Purchase Requests"
                to="/purchase-requests"
                icon={<NotificationImportantOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Consultant Requests"
                to="/consultant-requests"
                icon={<NotificationImportantOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Withdrawal Requests"
                to="/withdrawals"
                icon={<NotificationImportantOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Data
              </Typography>
              <MenuItemComp
                title="Sales Management"
                to="/sales"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Manage Team"
                to="/team"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Users Information"
                to="/users"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Marketers Information"
                to="/marketers"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                User Transactions
              </Typography>
              <MenuItemComp
                title="Investment Deposits"
                to="/investment-deposits"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="All Withdrawals"
                to="/withdrawals"
                icon={<AccountBalanceWalletOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 20px"}}
                        >
                            Withdrawals
                        </Typography> */}

              {/* <MenuItemComp
                            title="Pendings"
                            to="/pending-withdrawals"
                            icon={<AccountBalanceWalletOutlinedIcon />} 
                            selected={selected}
                            setSelected={setSelected}                       
                        />
                        <MenuItemComp
                            title="Completed"
                            to="/completed-withdrawals"
                            icon={<AccountBalanceOutlinedIcon />} 
                            selected={selected}
                            setSelected={setSelected}                       
                        />
                        <MenuItemComp
                            title="Declined"
                            to="/declined-withdrawals"
                            icon={<WalletOutlinedIcon />} 
                            selected={selected}
                            setSelected={setSelected}                       
                        /> */}

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Website Data
              </Typography>

              <MenuItemComp
                title="Investments Data"
                to="/investments-data"
                icon={<MoneyOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Projects"
                to="/projects"
                icon={<WorkOutlineIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Shop Listings"
                to="/shop-listings"
                icon={<ShopOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Events Gallery"
                to="/events-gallery"
                icon={<BrowseGalleryIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="FAQ"
                to="/faq"
                icon={<HelpOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Careers"
                to="/careers"
                icon={<HelpOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Posts"
                to="/posts"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <MenuItemComp
                title="Visas"
                to="/visas"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Contacts
              </Typography>
              <MenuItemComp
                title="Subscribers"
                to="/subscribers"
                icon={<NotificationImportantOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Settings
              </Typography>
              <IconButton onClick={handleLogout} sx={{ marginLeft: '20px' }}>
                <LogoutOutlinedIcon />
                <Typography>Logout</Typography>
              </IconButton>
            </Box>
          </Menu>
        </ProSidebar>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
    </Box>
  );
};

export default Sidebar;
