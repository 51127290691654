import { Box, Button, InputLabel, Input } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";
import { useState } from 'react';
import {Formik} from "formik";
import * as yup from "yup";

import axios from "axios";
import useFetch from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";

axios.defaults.withCredentials = true;

const EditGallery = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const { eventID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/gallery/gallery/${eventID}`);
    const history = useNavigate();
    const [images, setImages] = useState();
    const [eventCoverImage, setEventCoverImage] = useState();

    const initialValues = data ? {
        eventTitle: data.eventTitle,
        eventDetails: data.eventDetails,
    } : {
        eventTitle: "",
        eventDetails: "",
    }
    
    const validationSchema = yup.object().shape({
        eventTitle: yup.string().required("This field is required"),
        eventDetails: yup.string().required("This field is required"),
    })

    const handleImagesChange = (e) => { 
        setImages(e.target.files);
    }
    const handleCoverImageChange = (e) => { 
        setEventCoverImage(e.target.files[0]);
    }

    const handleSubmit = (values) => {
        const formData = new FormData();
        for(let i=0; i<images.length; i++) {
            formData.append(`images`, images[i])
        }
        formData.append('eventCoverImage', eventCoverImage);
        formData.append('eventTitle', values.eventTitle);
        formData.append('eventDetails', values.eventDetails);

        console.log(...formData);
        axios.put(`${process.env.REACT_APP_API_URL}/gallery/update-gallery/${eventID}`, formData, { withCredentials: true})
        .then(res => {
            if(res.status === 200) {
                console.log("Successfully published data")
            } else {
                console.log("Failed")
            }
        })
        .then(()=>history("/events-gallery"))
        .catch(err => console.log(err))
    }


    return <Box m="20px">
        <Box>
            <Header title="EDIT PROJECT DATA" subtitle="Edit The Details of this Project That Was Already Published to the Website" />    
        </Box>
        { data && <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >   

                            <Box sx={{ gridColumn: "span 4"}}>
                                <InputLabel id="eventTitle">Project Title</InputLabel>
                                <Input
                                    fullWidth 
                                    type="text"
                                    onChange={handleChange}
                                    name="eventTitle"
                                    value={values.eventTitle}
                                    error={!!touched.eventTitle && !!errors.eventTitle}
                                    helperText={touched.eventTitle && errors.eventTitle}
                                    onBlur={handleBlur}
                                />
                            </Box>
                            
                            <Box sx={{ gridColumn: "span 4"}}>
                                    <InputLabel id="eventDetails">Project Details</InputLabel>
                                <Input
                                    labelid="eventDetails"
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    multiline
                                    rows="3"
                                    onChange={handleChange}
                                    name="eventDetails"
                                    sx={{ gridColumn: "span 2"}}
                                    value={values.eventDetails}
                                    error={!!touched.eventDetails && !!errors.eventDetails}
                                    helperText={touched.eventDetails && errors.eventDetails}
                                    onBlur={handleBlur}
                                />
                            </Box>

                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="eventCoverImage">Cover Image</InputLabel>
                                <Input
                                    fullWidth
                                    variant="filled"
                                    type="file"
                                    label="Cover Image"
                                    placeholder="Cover Image"
                                    onChange={handleCoverImageChange}
                                    name="eventCoverImage"
                                    sx={{ gridColumn: "span 2"}}
                                    required
                                />
                            </Box>

                            <Box sx={{ gridColumn: "span 2"}}>
                                <InputLabel id="images" sx={{paddingBottom:"7px"}}>More Event Gallery Images</InputLabel>
                                <input 
                                    type="file" 
                                    name="images" 
                                    onChange={handleImagesChange} 
                                    multiple required />
                            </Box>
                            
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Update Event Gallery Data
                            </Button>
                        </Box>
                    </form>

                )
            }
        </Formik>}
        {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
        {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
    </Box>
}
 
export default EditGallery;