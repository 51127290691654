import { Box, Typography, useTheme} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";

import useFetchCredential from "../../hooks/useFetchCredential";

const Subscribers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/subscriptions/get-subscribers`);

    const columns = [
        {
            field: "fullName",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email Address",
            flex: 1,
        },
        {
            field: "phone",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Transaction Date",
            flex: 1,
            renderCell: (params) => {
                var dateString = new Date(params.row.date).toString();
                var splittedDateString = dateString.split(' ');
                var day = splittedDateString[0];
                var day2 = splittedDateString[1];
                var month = splittedDateString[2];
                var year = splittedDateString[3];
                var date = `${day}, ${day2} ${month}, ${year}`;
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Typography>{date}</Typography>
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="WITHDRAWAL TRANSACTIONS" subtitle="List of All Withdrawal Requests By Users (Ordered By Recent)"  />
        </Box> 
        <Box
            m="30px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >
            {data && <DataGrid 
                    checkboxSelection
                    rows={data.sort((a, b) => b.fullName - a.fullName)}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                    getRowId = {
                        (data) => {
                            return data.email;
                        }
                    }
                />
            }   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
        </Box>
    </Box>
}
 
export default Subscribers;