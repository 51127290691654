import { Box, Button, InputLabel, Input, Select, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/global/Header';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.css';

import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

axios.defaults.withCredentials = true;

const divElement = document.createElement('div');
divElement.className = styles['text-editor'];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    [{ direction: 'rtl' }],
  ],
};

const AddPost = () => {
  const isNoneMobile = useMediaQuery('(min-width:600px)');
  const history = useNavigate();
  const [coverImage, setCoverImage] = useState();
  const [post, setPost] = useState('');
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);

  const initialValues = {
    postTitle: '',
    category: '',
  };

  const validationSchema = yup.object().shape({
    postTitle: yup.string().required('Post title is required'),
    category: yup.string().required('Post category is required'),
  });

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('coverImage', coverImage);
    formData.append('postTitle', values.postTitle);
    formData.append('category', values.category);
    formData.append('post', post);

    console.log(...formData);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/posts/add-post`, formData, {
      withCredentials: true,
    });
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        history('/posts');
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  return (
    <Box m="20px">
      <Box>
        <Header title="ADD NEW POST" subtitle="Add a New Post Content to the Website" />
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form encType="multipart/form-data" onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': {
                  gridColumn: isNoneMobile ? undefined : 'span 4',
                },
              }}
              m="40px 0 0 0"
            >
              <Box sx={{ gridColumn: 'span 4' }}>
                <InputLabel id="postTitle">Post Title</InputLabel>
                <Input
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  name="postTitle"
                  value={values.postTitle}
                  error={!!touched.postTitle && !!errors.postTitle}
                  helperText={touched.postTitle && errors.postTitle}
                  onBlur={handleBlur}
                />
              </Box>

              <Box
                sx={{
                  gridColumn: 'span 4',
                  height: '50vh',
                  background: '#fff',
                  color: '#121212',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 20px',
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={post}
                  onChange={setPost}
                  modules={modules}
                  className="text-editor"
                />
              </Box>

              <Box
                sx={{ gridColumn: 'span 2', backgroundColor: 'transparent', position: 'relative' }}
              >
                <InputLabel
                  id="select"
                  sx={{
                    position: 'absolute',
                    zIndex: '1',
                    marginTop: '2px',
                    paddingLeft: '10px',
                  }}
                >
                  Post Category
                </InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  labelId="select"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.status}
                  name="category"
                  error={!!touched.category && !!errors.category}
                  sx={{
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    zIndex: '111',
                    top: '0px',
                    height: '50px',
                  }}
                >
                  <MenuItem value="Learn">Learn</MenuItem>
                  <MenuItem value="Industry News">Industry News</MenuItem>
                  <MenuItem value="Investment Update">Investment Update</MenuItem>
                  <MenuItem value="Visa Opportunity">Visa Opportunity</MenuItem>
                </Select>
              </Box>

              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="coverImage">Cover Image</InputLabel>
                <Input
                  fullWidth
                  variant="filled"
                  type="file"
                  label="Cover Image"
                  placeholder="Cover Image"
                  onChange={handleCoverImageChange}
                  name="coverImage"
                  sx={{ gridColumn: 'span 2' }}
                  required
                />
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Publish Post
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddPost;
