import { Box, CardContent, Typography, Card, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/global/Header';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { formatter } from '../../utils';

const SaleDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { saleID } = useParams();
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/listings/single-sale/${saleID}`
  );

  let date;
  if (data) {
    var dateString = new Date(data.date).toString();
    var splittedDateString = dateString.split(' ');
    var day = splittedDateString[0];
    var day2 = splittedDateString[1];
    var month = splittedDateString[2];
    var year = splittedDateString[3];
    date = `${day}, ${day2} ${month}, ${year}`;
  }

  return (
    <Box m="20px">
      <Box>
        <Header
          title={`Sales of - ${data ? data.title : 'Enquiry Details'}`}
          subtitle={`Here is the Details for a salaes of ${data ? data.title : 'a property'} to ${
            data ? data.fullName : 'a buyer'
          } via the website`}
        />
      </Box>

      <Box marginTop="70px" display="flex" justifyContent="center" alignItems="center">
        <Box backgroundColor={colors.primary[400]} width="42%">
          {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
          {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
          {data && (
            <Card
              sx={{
                background: `${colors.primary[400]} !important`,
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 5px 12px 5px #000c02d8 !important;',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '30px',
                }}
              >
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography sx={{ fontSize: '20px' }} variant="h5">
                    {data.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: `${colors.grey[300]}`,
                      border: `1px solid ${colors.grey[600]}`,
                      display: 'inline-block',
                      padding: '2px 10px',
                      margin: '3px',
                    }}
                  >
                    Sold On: {date}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                    Sold to: {data.fullName}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `${colors.primary[500]} !important`,
                  width: '100%',
                  padding: '13px 40px',
                }}
              >
                <>
                  <Typography
                    sx={{
                      color: `${colors.grey[100]}`,
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    Property Details
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Property:</Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.title}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Property ID:</Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.listingID}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>
                      Purchase Request ID:
                    </Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.enquiryID}
                    </Typography>
                  </Box>
                </>

                <>
                  <Typography
                    sx={{
                      color: `${colors.grey[100]}`,
                      fontSize: '16px',
                      fontWeight: '600',
                      marginTop: '20px',
                    }}
                  >
                    Transaction Details
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Amount Paid:</Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {formatter.format(data.amount)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>
                      Commission To Referrers:
                    </Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {formatter.format(data.commision)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>
                      Amount Received by Company:
                    </Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {formatter.format(data.companyAmount)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Payment Receipt:</Typography>
                    <Link
                      to={`${process.env.REACT_APP_FILES_BASEURL}/sales/${data.transactionReceipt}`}
                    >
                      <Typography sx={{ color: `${colors.greenAccent[500]}`, fontWeight: '600' }}>
                        {data.transactionReceipt}
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Sales Invoice:</Typography>
                    <Link
                      to={`${process.env.REACT_APP_FILES_BASEURL}/sales/${data.purchaseInvoice}`}
                    >
                      <Typography sx={{ color: `${colors.greenAccent[500]}`, fontWeight: '600' }}>
                        {data.purchaseInvoice}
                      </Typography>
                    </Link>
                  </Box>
                </>

                <>
                  <Typography
                    sx={{
                      color: `${colors.grey[100]}`,
                      fontSize: '16px',
                      fontWeight: '600',
                      marginTop: '20px',
                    }}
                  >
                    Buyer Details
                  </Typography>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Email:</Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.email}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>Phone Number:</Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.phone}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="5px"
                    sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                  >
                    <Typography sx={{ color: `${colors.grey[200]}` }}>
                      Heard about GTMPM through:
                    </Typography>
                    <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                      {data.source}
                    </Typography>
                  </Box>
                </>
                <br />
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SaleDetails;
