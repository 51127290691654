import { Box, CardContent, Typography, Card, useTheme, Badge, Avatar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { Link, useParams } from "react-router-dom";
import useFetchCredential from "../../hooks/useFetchCredential";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const AdminProfile = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { id } = useParams();
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/team/profile/${id}`);

    console.log(data ? data : "no data")

    return <Box m="20px">
        <Box>
            <Header 
                title={`Team member - ${data ? data.fullName + "'s Profile" : 'Team Member Profile'}`} 
                subtitle={`Here is the User Profile Page for ${data && data.fullName}`} 
            />    
        </Box>

        <Box
            marginTop="60px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="30%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "50px 30px",
                            }}
                        >   
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >   
                                <Link to={`${process.env.REACT_APP_IMG_BASEURL}/team/${data.coverImage}`}>
                                    <Avatar
                                        alt={`${process.env.REACT_APP_IMG_BASEURL}/team/${data.coverImage}`}
                                        src={`${process.env.REACT_APP_IMG_BASEURL}/team/${data.coverImage}`}
                                        sx={{ width: 200, height: 200 }}
                                    />
                                </Link>
                            </StyledBadge>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">
                                    {data.fullName}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: `${colors.grey[200]}`
                                    }}
                                >
                                    Access level: {data.access}
                                </Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: `${
                                                data.subscribedTo === "" ? colors.redAccent[200]
                                                : colors.greenAccent[500]
                                            }`
                                    }}
                                >
                                    {data.post}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px 40px",
                           }} 
                        >


                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Email:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.email}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Phone:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>
                                    <Link to={`tel:${data.contact}`} style={{color:`${colors.grey[300]}`, textDecoration: 'none'}}>{data.contact}</Link>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Linkedin Profile:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.linkedin}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Instagram Profile:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.instagram}</Typography>
                            </Box>
                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default AdminProfile;