import { Box, IconButton, useTheme} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Header from "../../components/global/Header";
import { Link } from "react-router-dom";
import styled from "styled-components";

import axios from "axios";
import useFetch from "../../hooks/useFetch";

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const InvestmentsData = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/investments/get-investment-data`);

    const deleteInvestmentData = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/investments/delete-investment/${id}`)
        .then(window.location.reload());
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 0.5
        },
        {
            field: "title",
            headerName: "Package Title",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "min",
            headerName: "Minimum Amount",
            flex: 1,
        },
        {
            field: "max",
            headerName: "Maximum Amount",
            flex: 1,
        },
        {
            field: "roi",
            headerName: "Return on Investment",
            flex: 1,
        },
        {
            headerName: "Actions",
            flex: 1,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`/edit-investment-data/${params.row.id}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteInvestmentData(params.row.id)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="INVESTMENT DATA" subtitle="Manage the List of GTMPM Investment Packages Here"  />
            <Box>
                <StyledLink
                    to="/add-investment-data"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <MoneyOffOutlinedIcon sx={{ mr: "10px" }} />
                        Add New Investment Plan
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="40px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                }
            }}
        >   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && <DataGrid 
                checkboxSelection
                rows={data}
                columns={columns}
                components={{Toolbar: GridToolbar}}
                getRowId = {
                    (data) => {
                        return data.id;
                    }
                }
            />}
        </Box>
    </Box>
}
 
export default InvestmentsData;