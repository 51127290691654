import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Topbar from './pages/global/Topbar';
import Sidebar from './pages/global/Sidebar';
import Dashboard from './pages/dashboard';
import Team from './pages/team';
import AddTeamMember from './pages/team/add-team-member';
import Users from './pages/users';
import UserTransactions from './pages/transactions';
import FAQ from './pages/faq';
import PendingWithdrawals from './pages/withdrawals/pending';
import DeclinedWithdrawals from './pages/withdrawals/declined';
import CompletedWithdrawals from './pages/withdrawals/completed';
import Projects from './pages/projects';
import AddProject from './pages/projects/add-project';
import Shop from './pages/shop';
import AddListing from './pages/shop/add-listing';
import EventsGallery from './pages/gallery';
import AddEventGallery from './pages/gallery/add-event-gallery';
import AddFAQ from './pages/faq/add-faq';
import SiteData from './pages/site-data';
import Profile from './pages/team/profile';
import EditAdmin from './pages/team/edit-admin';
import AdminProfile from './pages/team/admin-profile';
import UserProfile from './pages/users/profile';
import NotFound from './pages/404';
import Withdrawals from './pages/withdrawals';
import InvestmentsData from './pages/investments/investments-data';
import AddInvestmentData from './pages/investments/add-investment-data';
import EditInvestmentData from './pages/investments/edit-investment-data';
import EditFAQ from './pages/faq/edit';
import ListingDetails from './pages/shop/listing-details';
import GalleryDetails from './pages/gallery/gallery-details';
import ProjectDetails from './pages/projects/project-details';
import EditProject from './pages/projects/edit-project';
import EditListing from './pages/shop/edit-listing';
import EditGallery from './pages/gallery/edit-gallery';
import Careers from './pages/careers';
import AddCareer from './pages/careers/add-career';
import EditCareer from './pages/careers/edit';
import JobApplications from './pages/careers/applications';
import ApplicationDetails from './pages/careers/application-details';
import ListingEnquiries from './pages/shop/enquiries';
import EnquiryDetails from './pages/shop/enquiry-details';
import UserTransactionDetails from './pages/investments/transaction-details';
import useFetchCredential from './hooks/useFetchCredential';
import Subscribers from './pages/subscribers';
import AddPost from './pages/posts/add-post';
import EditPost from './pages/posts/edit-post';
import Posts from './pages/posts';
import Visas from './pages/visas';
import AddVisaRecord from './pages/visas/add-visa-record';
import EditVisaRecord from './pages/visas/edit-visa-record';
import Marketers from './pages/marketers';
import MarketerProfile from './pages/marketers/profile';
import PurchaseRequests from './pages/requests/purchase-requests';
import RecordSale from './pages/requests/record-sale';
import Sales from './pages/requests/sales';
import SaleDetails from './pages/requests/sale-details';
import ConsultantRequests from './pages/requests/consultant-requests';
import WithdrwalDetails from './pages/withdrawals/withdrawal-details';

function App() {
  const [theme, colorMode] = useMode();

  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/team/dashboard`
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {data && (
          <div className="app">
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <Sidebar />
            <main className="content">
              <Topbar />
              <Routes>
                <Route path="/" element={<Dashboard />} />

                {/* Team */}
                <Route path="/team" element={<Team />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/edit-admin/:teamID" element={<EditAdmin />} />
                <Route path="/team-member-profile/:id" element={<AdminProfile />} />
                <Route path="/add-team-member" element={<AddTeamMember />} />

                {/* User */}
                <Route path="/users" element={<Users />} />
                <Route path="/user-profile/:userID" element={<UserProfile />} />

                {/* Marketers */}
                <Route path="/marketers" element={<Marketers />} />
                <Route path="/marketer-profile/:userID" element={<MarketerProfile />} />

                {/* <Route path="/user-investments-list" element={<UserInvestments />} /> */}

                <Route path="/investments-data" element={<InvestmentsData />} />
                <Route path="/add-investment-data" element={<AddInvestmentData />} />
                <Route path="/edit-investment-data/:id" element={<EditInvestmentData />} />
                <Route path="/withdrawals" element={<Withdrawals />} />
                <Route path="/pending-withdrawals" element={<PendingWithdrawals />} />
                <Route path="/declined-withdrawals" element={<DeclinedWithdrawals />} />
                <Route path="/completed-withdrawals" element={<CompletedWithdrawals />} />
                <Route path="/withdrawal-details/:txId" element={<WithdrwalDetails />} />

                {/* Website Content */}
                <Route path="/projects" element={<Projects />} />
                <Route path="/project-details/:projectID" element={<ProjectDetails />} />
                <Route path="/add-project" element={<AddProject />} />
                <Route path="/edit-project/:projectID" element={<EditProject />} />

                <Route path="/shop-listings" element={<Shop />} />
                <Route path="/purchase-requests" element={<PurchaseRequests />} />
                <Route path="/consultant-requests" element={<ConsultantRequests />} />
                <Route path="/record-sale" element={<RecordSale />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/sale-details/:saleID" element={<SaleDetails />} />

                <Route path="/listing-enquiries/:listingID" element={<ListingEnquiries />} />
                <Route path="/enquiry-details/:enquiryID" element={<EnquiryDetails />} />
                <Route path="/add-listing" element={<AddListing />} />
                <Route path="/edit-listing/:listingID" element={<EditListing />} />
                <Route path="/listing-details/:listingID" element={<ListingDetails />} />

                <Route path="/events-gallery" element={<EventsGallery />} />
                <Route path="/event-details/:eventID" element={<GalleryDetails />} />
                <Route path="/add-event-gallery" element={<AddEventGallery />} />
                <Route path="/edit-event-gallery/:eventID" element={<EditGallery />} />

                <Route path="/faq" element={<FAQ />} />
                <Route path="/add-faq" element={<AddFAQ />} />
                <Route path="/edit-faq/:faqID" element={<EditFAQ />} />

                <Route path="/careers" element={<Careers />} />
                <Route path="/add-career" element={<AddCareer />} />
                <Route path="/edit-career/:careerID" element={<EditCareer />} />
                <Route path="/career-applications/:careerID" element={<JobApplications />} />
                <Route
                  path="/application-details/:applicationID"
                  element={<ApplicationDetails />}
                />

                <Route path="/posts" element={<Posts />} />
                <Route path="/add-post" element={<AddPost />} />
                <Route path="/edit-post/:postID" element={<EditPost />} />

                <Route path="/investment-deposits" element={<UserTransactions />} />
                <Route
                  path="/investment-deposit-details/:transactionID"
                  element={<UserTransactionDetails />}
                />
                <Route path="/subscribers" element={<Subscribers />} />

                <Route path="/visas" element={<Visas />} />
                <Route path="/add-visa-record" element={<AddVisaRecord />} />
                <Route path="/edit-visa-record/:visaID" element={<EditVisaRecord />} />

                <Route path="/site-data" element={<SiteData />} />

                <Route path="/*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
        )}
        {loading && <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '25px' }}>{error}</div>}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
