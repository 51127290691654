import { Box, Button, InputLabel, Input, Select, MenuItem } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/global/Header';
import { useState } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import axios from 'axios';
import useFetch from '../../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';

axios.defaults.withCredentials = true;

const EditListing = () => {
  const isNoneMobile = useMediaQuery('(min-width:600px)');
  const { listingID } = useParams();
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/listings/listing/${listingID}`
  );
  const history = useNavigate();
  const [images, setImages] = useState();
  const [coverImage, setCoverImage] = useState();

  const initialValues = data
    ? {
        listingTitle: data.listingTitle,
        price: data.price,
        listingDetails: data.listingDetails,
        propertyType: data.propertyType,
        propertyClass: data.propertyClass,
        offerType: data.offerType,
        bedrooms: data.bedrooms,
        bathrooms: data.bathrooms,
        propertySize: data.propertySize,
        neighborhood: data.neighborhood,
        city: data.city,
        country: data.country,
        features: data.features,
      }
    : {
        listingTitle: '',
        price: '',
        listingDetails: '',
        propertyType: '',
        propertyClass: '',
        offerType: '',
        bedrooms: '',
        bathrooms: '',
        propertySize: '',
        neighborhood: '',
        city: '',
        country: '',
        features: '',
      };

  const validationSchema = yup.object().shape({
    listingTitle: yup.string().required('This field is required'),
    price: yup.string().required('This field is required'),
    listingDetails: yup.string().required('This field is required'),
    propertyType: yup.string().required('This field is required'),
    propertyClass: yup.string().required('This field is required'),
    offerType: yup.string().required('This field is required'),
    bedrooms: yup.string().required('This field is required'),
    bathrooms: yup.string().required('This field is required'),
    propertySize: yup.string().required('This field is required'),
    neighborhood: yup.string().required('This field is required'),
    city: yup.string().required('This field is required'),
    country: yup.string().required('This field is required'),
    features: yup.string().required('This field is required'),
  });

  const handleImagesChange = (e) => {
    setImages(e.target.files);
  };
  const handleProjectCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
    }
    formData.append('coverImage', coverImage);
    formData.append('listingTitle', values.listingTitle);
    formData.append('price', values.price);
    formData.append('listingDetails', values.listingDetails);
    formData.append('propertyType', values.propertyType);
    formData.append('propertyClass', values.propertyClass);
    formData.append('offerType', values.offerType);
    formData.append('bedrooms', values.bedrooms);
    formData.append('bathrooms', values.bathrooms);
    formData.append('propertySize', values.propertySize);
    formData.append('neighborhood', values.neighborhood);
    formData.append('city', values.city);
    formData.append('country', values.country);
    formData.append('features', values.features);

    console.log(...formData);
    axios
      .put(`${process.env.REACT_APP_API_URL}/listings/update-listing/${listingID}`, formData, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log('Successfully published data');
        } else {
          console.log('Failed');
        }
      })
      .then(() => history('/shop-listings'))
      .catch((err) => console.log(err));
  };

  return (
    <Box m="20px">
      <Box>
        <Header
          title="EDIT PROPERTY FOR SALE"
          subtitle="Edit this Property that is Made Avaialable for Sale on the Website"
        />
      </Box>
      {data && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                sx={{
                  '& > div': {
                    gridColumn: isNoneMobile ? undefined : 'span 6',
                  },
                }}
                m="40px 0 0 0"
              >
                <Box sx={{ gridColumn: 'span 3' }}>
                  <InputLabel id="listingTitle">Listing Title</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    name="listingTitle"
                    value={values.listingTitle}
                    error={!!touched.listingTitle && !!errors.listingTitle}
                    helperText={touched.listingTitle && errors.listingTitle}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="price">Listing Price</InputLabel>
                  <Input
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="price"
                    value={values.price}
                    error={!!touched.price && !!errors.price}
                    helperText={touched.price && errors.price}
                    onBlur={handleBlur}
                  />
                </Box>
                <Box sx={{ gridColumn: 'span 1' }}>
                  <InputLabel id="price">QTY Available</InputLabel>
                  <Input
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="qty"
                    value={values.qty}
                    error={!!touched.qty && !!errors.qty}
                    helperText={touched.qty && errors.qty}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 6' }}>
                  <InputLabel id="listingDetails">Listing Details</InputLabel>
                  <Input
                    labelid="listingDetails"
                    fullWidth
                    variant="filled"
                    type="text"
                    multiline
                    rows="3"
                    onChange={handleChange}
                    name="listingDetails"
                    sx={{ gridColumn: 'span 2' }}
                    value={values.listingDetails}
                    error={!!touched.listingDetails && !!errors.listingDetails}
                    helperText={touched.status && errors.status}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="price">Property Size</InputLabel>
                  <Input
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="propertySize"
                    value={values.propertySize}
                    error={!!touched.propertySize && !!errors.propertySize}
                    helperText={touched.propertySize && errors.propertySize}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box
                  sx={{
                    gridColumn: 'span 2',
                    backgroundColor: 'transparent',
                    position: 'relative',
                  }}
                >
                  <InputLabel id="select">Property Type</InputLabel>
                  <Select
                    fullWidth
                    variant="filled"
                    labelId="select"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.propertyType}
                    name="propertyType"
                    error={!!touched.propertyType && !!errors.propertyType}
                    sx={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      zIndex: '111',
                      top: '0px',
                      height: '50px',
                    }}
                  >
                    <MenuItem>Select Type</MenuItem>
                    <MenuItem value="House">House</MenuItem>
                    <MenuItem value="Land">Land</MenuItem>
                    <MenuItem value="Shop">Shop</MenuItem>
                    <MenuItem value="Mall">Mall</MenuItem>
                    <MenuItem value="Store">Store</MenuItem>
                    <MenuItem value="Warehouse">Warehouse</MenuItem>
                    <MenuItem value="Office Space">Office Space</MenuItem>
                  </Select>
                </Box>

                <Box
                  sx={{
                    gridColumn: 'span 2',
                    backgroundColor: 'transparent',
                    position: 'relative',
                  }}
                >
                  <InputLabel id="price">Property Class</InputLabel>
                  <Select
                    fullWidth
                    variant="filled"
                    labelId="select"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.propertyClass}
                    name="propertyClass"
                    error={!!touched.propertyClass && !!errors.propertyClass}
                    sx={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      zIndex: '111',
                      top: '0px',
                      height: '50px',
                    }}
                  >
                    <MenuItem>Select Property Class</MenuItem>
                    <MenuItem value="Luxury">Luxury</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Developing">Developing</MenuItem>
                  </Select>
                </Box>

                <Box
                  sx={{
                    gridColumn: 'span 2',
                    backgroundColor: 'transparent',
                    position: 'relative',
                  }}
                >
                  <InputLabel id="price">Offer Type</InputLabel>
                  <Select
                    fullWidth
                    variant="filled"
                    labelId="select"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.offerType}
                    name="offerType"
                    error={!!touched.offerType && !!errors.offerType}
                    sx={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      zIndex: '111',
                      top: '0px',
                      height: '50px',
                    }}
                  >
                    <MenuItem>Select Offer Type</MenuItem>
                    <MenuItem value="For Sale">For Sale</MenuItem>
                    <MenuItem value="For Lease">For Lease</MenuItem>
                    <MenuItem value="To Let">To Let</MenuItem>
                  </Select>
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="price">Bedrooms</InputLabel>
                  <Input
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="bedrooms"
                    value={values.bedrooms}
                    error={!!touched.bedrooms && !!errors.bedrooms}
                    helperText={touched.bedrooms && errors.bedrooms}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="price">Bathrooms</InputLabel>
                  <Input
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    name="bathrooms"
                    value={values.bathrooms}
                    error={!!touched.bathrooms && !!errors.bathrooms}
                    helperText={touched.bathrooms && errors.bathrooms}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 6' }}>
                  <InputLabel id="address">Property Full Adress</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    multiline
                    rows="2"
                    onChange={handleChange}
                    name="address"
                    value={values.address}
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="neighborhood">Neighborhood</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    name="neighborhood"
                    value={values.neighborhood}
                    error={!!touched.neighborhood && !!errors.neighborhood}
                    helperText={touched.neighborhood && errors.neighborhood}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="city">City</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    name="city"
                    value={values.city}
                    error={!!touched.city && !!errors.city}
                    helperText={touched.city && errors.city}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="country">Country</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    name="country"
                    value={values.country}
                    error={!!touched.country && !!errors.country}
                    helperText={touched.country && errors.country}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 6' }}>
                  <InputLabel id="features">Property Features (separate with comma)</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    multiline
                    rows="3"
                    onChange={handleChange}
                    name="features"
                    value={values.features}
                    error={!!touched.features && !!errors.features}
                    helperText={touched.features && errors.features}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 3' }}>
                  <InputLabel id="projectDetails">Cover Image</InputLabel>
                  <Input
                    fullWidth
                    variant="filled"
                    type="file"
                    label="Cover Image"
                    placeholder="Cover Image"
                    onChange={handleProjectCoverImageChange}
                    name="coverImage"
                    sx={{ gridColumn: 'span 2' }}
                    required
                  />
                </Box>

                <Box sx={{ gridColumn: 'span 3' }}>
                  <InputLabel id="projectDetails" sx={{ paddingBottom: '7px' }}>
                    More Property Images
                  </InputLabel>
                  <input
                    type="file"
                    name="images"
                    onChange={handleImagesChange}
                    multiple
                    required
                    style={{ borderBottom: '1px solid #eee', width: '100%' }}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Update Property Data
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
    </Box>
  );
};

export default EditListing;
