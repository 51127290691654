import { Box, CardContent, Typography, Card, useTheme, Badge } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const GalleryDetails = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { eventID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/gallery/gallery/${eventID}`);

    let newArray;

    if(data) {
        const array = data.images;
        newArray = array.split(', ');
    } else {
        newArray = undefined;
    }

    return <Box m="20px">
        <Box>
            <Header 
                title={`Event - ${data ? data.eventTitle : 'Event Details'}`} 
                subtitle="Here is the Event Details Page" 
            />    
        </Box>

        <Box
            marginTop="60px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="37%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "30px",
                            }}
                        >   
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >   <Link to={`${process.env.REACT_APP_IMG_BASEURL}/gallery/${data.eventCoverImage}`}>
                                    <img
                                        alt="Remy Sharp"
                                        src={`${process.env.REACT_APP_IMG_BASEURL}/gallery/${data.eventCoverImage}`}
                                        width="100%" height="200"
                                    />
                                </Link>
                            </StyledBadge>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">
                                    {data.eventTitle}
                                </Typography>
                                <Typography sx={{ color: `${colors.grey[200]}` }}>
                                    {data.eventDetails}
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >   
                            <Typography
                                sx={{
                                    color: `${colors.grey[100]}`,
                                    margin: "3px"
                                }}
                                variant="h5"
                            >Event Images</Typography>
                            {
                                
                                newArray && newArray.map((image) => (
                                    <Box sx={{
                                        border: `1px solid ${colors.grey[300]}`,
                                        display: 'inline-block',
                                        padding: "5px",
                                        margin: "0.5%",
                                        width: "24%"
                                    }}>
                                        <Link to={`${process.env.REACT_APP_IMG_BASEURL}/gallery/${image}`}>
                                            <img
                                                alt={image}
                                                src={`${process.env.REACT_APP_IMG_BASEURL}/gallery/${image}`}
                                                width="100%" height="75"
                                            />
                                        </Link>
                                    </Box>
                                ))

                                
                            }
                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default GalleryDetails;