import { Box, Button, TextField, Select, MenuItem, InputLabel, } from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

axios.defaults.withCredentials = true;

const AddTeamMember = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const history = useNavigate();
    const [coverImage, setCoverImage] = useState();

    const initialValues = {
        status: "",
    }
    
    const validationSchema = yup.object().shape({
        status: yup.string().required("status Level is required")
    })

    const handleCoverImageChange = (e) => { 
        setCoverImage(e.target.files[0]);
    }

    const handleSubmit = (values) => {
        const formData = new FormData();
        formData.append('status', values.status);
        formData.append('coverImage', coverImage);
        axios.post(`${process.env.REACT_APP_API_URL}/visas/post-visa-record`, formData, { withCredentials: true})
        .then(res => {
            if(res.status === 200) {
                console.log("Successfully published data")
            } else {
                console.log("Failed")
            }
        })
        .then(()=>history("/visas"))
        .catch(err => console.log(err))
    }

    return <Box m="20px">
        <Box>
            <Header title="ADD NEW VISA RECORD" subtitle="Create a new visa record for the website" />    
        </Box>
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >
                                                      

                            <Box
                                sx={{ gridColumn: "span 2", backgroundColor: "transparent", position: "relative"}}
                            >
                                <InputLabel id="select"
                                    sx={{
                                        position: "absolute",
                                        zIndex: "1",
                                        marginTop: "2px",
                                        paddingLeft: "10px"
                                    }}
                                >Select Visa Status</InputLabel>
                                <Select
                                    fullWidth
                                    variant="filled"
                                    labelId="select"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.status}
                                    name="status"
                                    error={!!touched.status && !!errors.status}
                                    sx={{ 
                                        backgroundColor: "transparent", 
                                        position: "absolute",
                                        zIndex: "111",
                                        top: "0px",
                                        height: "50px"
                                    }}
                                >
                                    <MenuItem value="Pending">Pending</MenuItem>
                                    <MenuItem value="Completed">Completed</MenuItem>
                                    <MenuItem value="Declined">Declined</MenuItem>
                                </Select>
                            </Box>

                            <TextField
                                fullWidth
                                variant="filled"
                                type="file"
                                label="Official Passport"
                                onBlur={handleBlur}
                                onChange={handleCoverImageChange}
                                name="coverImage"
                                sx={{ gridColumn: "span 2"}}
                                required
                            />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Add Visa Record
                            </Button>
                        </Box>
                        
                    </form>
                )
            }
        </Formik>
    </Box>
}
 
export default AddTeamMember;