import { Box, IconButton, useTheme } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Header from "../../components/global/Header";

import axios from "axios";
import useFetch from "../../hooks/useFetch";

const JobApplications = () => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { careerID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/careers/get-job-applications/${careerID}`);
    const {data:careerData, loading:careerLoading, error:careerError} = useFetch(`${process.env.REACT_APP_API_URL}/careers/career/${careerID}`);

    const deleteTeamMember = (applicationID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/careers/delete-application/${applicationID}`)
        .then(window.location.reload());;
    };

    const columns = [
        {
            field: "applicationID",
            headerName: "Application ID",
            flex: 0.7,
            cellClassName: "name-column--cell",
        },
        {
            field: "firstName",
            headerName: "First Name",
            flex: 0.5,
            cellClassName: "name-column--cell",
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 0.5,
            cellClassName: "name-column--cell",
        },
        {
            field: "experience",
            headerName: "Years of Experience",
            headerAlign: "left",
            flex: 1,
            align: "left",
        },
        {
            field: "phone",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            headerName: "Actions",
            flex: 0.7,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`/application-details/${params.row.applicationID}`}>   
                            <IconButton>
                                <RemoveRedEyeOutlined sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteTeamMember(params.row.applicationID)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title={careerData ? `Job Applications For  - ${careerData.title}` : 'JOB APPLICATIONS'}
            subtitle="Here are the Job Applications for this Offer"  />
            {careerLoading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {careerError && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
        </Box> 
        <Box
            m="40px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && <DataGrid checkboxSelection components={{Toolbar: GridToolbar}} rows={data} columns={columns} />}
            
        </Box>
    </Box>
}
 
export default JobApplications;