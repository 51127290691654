import { Box, CardContent, Typography, Card, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import DocViewer from "@cyntler/react-doc-viewer";


const ApplicationDetails = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { applicationID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/careers/application/${applicationID}`);

    const docs = [
        { uri: data? `${process.env.REACT_APP_FILES_BASEURL}/job-applications/${data.resume}` : "incomg"}
    ];

    return <Box m="20px">
        <Box>
            <Header 
                title={`Application - ${data ? data.title : 'Listing Details'}`} 
                subtitle="Here is the Details for a Page" 
            />    
        </Box>

        <Box
            marginTop="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="55%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "30px",
                            }}
                        >   
                            
                                <Link to={`${process.env.REACT_APP_FILES_BASEURL}/job-applications/${data.resume}`}>
                                    <DocViewer
                                        documents={docs}
                                        activeDocument={docs[0]}
                                        // onDocumentChange={handleDocumentChange}
                                        style={{height:"300px", width:"100%"}}
                                    />
                                </Link>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">
                                    Name: {`${data.firstName} ${data.lastName}`}
                                </Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography
                                    variant="h5"
                                    sx={{color:colors.greenAccent[500]}}
                                >
                                    Applying For: {data.title}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Experience: <b>{data.experience}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Email: <b>{data.email}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Phone Number: <b>{data.phone}</b>
                            </Typography>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[400]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >   
                            <Typography
                                sx={{
                                    color: `${colors.grey[100]}`,
                                    margin: "3px"
                                }}
                                variant="h5"
                            >Cover Letter</Typography>
                            <Typography sx={{
                                color: `${colors.grey[100]}`,
                                border: `1px solid ${colors.grey[300]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                <b>{data.cover}</b>
                            </Typography>
                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default ApplicationDetails;