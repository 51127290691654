import { Box, CardContent, Typography, Card, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";



const UserTransactionDetails = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { transactionID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/transactions/fetch-transaction/${transactionID}`);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    let subDate;
    let expiryDate;

    if (data) {
        var dateString = new Date(data.subDate).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        subDate = `${day}, ${day2} ${month}, ${year}`;

        var dateString33 = new Date(data.expiryDate).toString();
        var splittedDateString33 = dateString33.split(' ');
        var day33 = splittedDateString33[0];
        var day233 = splittedDateString33[1];
        var month33 = splittedDateString33[2];
        var year33 = splittedDateString33[3];
        expiryDate = `${day33}, ${day233} ${month33}, ${year33}`;
    }


    return <Box m="20px">
        <Box>
            <Header 
                title={`Investment Transaction - ${data ? data.transactionID : 'Event Details'}`} 
                subtitle="Here is the details for the investment plan transaction done by a user" 
            />    
        </Box>

        <Box
            marginTop="60px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="37%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "30px",
                            }}
                        >   
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5" sx={{color: `${colors.greenAccent[300]}`, fontSize:"22px"}}>
                                    { formatter.format(data.amount) }
                                </Typography>
                                <Typography sx={{ color: `${colors.grey[200]}` }}>
                                    {data.status}
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px 50px",
                           }} 
                           
                        >   
                            <Box display="flex" justifyContent="space-between" padding="7px 0">
                                <Typography sx={{color: `${colors.grey[200]}`}}>User Email:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.email}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="7px 0">
                                <Typography sx={{color: `${colors.grey[200]}`}}>User Name:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.name}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="3px" sx={{border: `1px dashed #fff`, margin:"10px 0"}}>
                                <Typography sx={{color: `${colors.greenAccent[300]}`, fontSize:"16px"}}>Payment For:</Typography>
                                <Typography sx={{color: `${colors.greenAccent[300]}`, fontSize:"16px", fontWeight:"600"}}>{data.plan} Investment Plan</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between"v>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Subscription Date:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{subDate}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="7px 0">
                                <Typography sx={{color: `${colors.grey[200]}`}}>Withdrawal Date:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{expiryDate}</Typography>
                            </Box>
                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default UserTransactionDetails;