import { Box } from "@mui/material";
import Header from "../../components/global/Header";
import PieChart from "../../components/global/PieChart";

const SiteData = () => {
    return <Box m="20px">
        <Box>
            <Header title="SITE DATA" subtitle="Website Use Statistics" />
        </Box>

        <Box height="75vh" mt="30px">
            <PieChart />
        </Box>
    </Box>
}
 
export default SiteData;