import { Box, Typography, IconButton, useTheme} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import styled from "styled-components";

import axios from "axios";
import useFetch from "../../hooks/useFetch";

const Projects = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/projects/get-all-projects`);

    const deleteData = (projectID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/projects/delete-project/${projectID}`)
        .then(window.location.reload());
    };

    const columns = [
        {
            field: "projectID",
            headerName: "Project ID",
            flex: 1,
        },
        {
            field: "projectCoverImage",
            headerName: "Project Image",
            flex: 1,
            renderCell: ({ row : { projectCoverImage }}) => {
                return (
                    <Box>
                        <img 
                            height="30px"
                            width="30px"
                            src={`${process.env.REACT_APP_IMG_BASEURL}/projects/${projectCoverImage}`} 
                            alt="" 
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                )
            }
        },
        {
            field: "projectTitle",
            headerName: "Project Title",
            flex: 1,
        },
        {
            field: "projectDetails",
            headerName: "Project Details",
            flex: 1,
            renderCell: ({ row : { projectDetails }}) => {
                return (
                    <Box
                    >
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {
                                projectDetails.substring(0, 30)
                            }...
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: ({ row : { status }}) => {
                return (
                    <Box
                        width="60%"
                        m="0 0 0 0"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            status === "Pending" ? colors.redAccent[700] : 
                            status === "Completed" ? colors.greenAccent[700] :
                            colors.blueAccent[700]
                        }
                        borderRadius = "4px"
                    >
                        <PendingOutlinedIcon />
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {status}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            headerName: "Actions",
            flex: 0.6,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`/project-details/${params.row.projectID}`}>   
                            <IconButton>
                                <RemoveRedEyeOutlined sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <Link to={`/edit-project/${params.row.projectID}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteData(params.row.projectID)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    const StyledLink = styled(Link)`
        text-decoration: none;
    `;

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="GTMPM PROJECTS" subtitle="List of Past, Recent and Completed Projects Done, Handled or Managed By GTMPM (Ordered By Recent)"  />

            <Box>
                <StyledLink
                    to="/add-project"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <WorkOutlinedIcon sx={{ mr: "10px" }} />
                        Add A NEW PROJECT
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="30px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && <DataGrid 
                checkboxSelection
                rows={data}
                columns={columns}
                components={{Toolbar: GridToolbar}}
                getRowId = {
                    (data) => {
                        return data.projectID;
                      }
                }
            />}
        </Box>
    </Box>
}
 
export default Projects;