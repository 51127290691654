import { Box, Typography, IconButton, useTheme } from "@mui/material";
import Header from "../../components/global/Header";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";
import useFetch from "../../hooks/useFetch";

const StyledLink = styled(Link)`
        text-decoration: none;
    `;

const FAQ = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/faqs/get-faqs`);

    const deleteData = (faqID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/faqs/delete-faq/${faqID}`)
        .then(window.location.reload());;
    };

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

            <Box>
                <StyledLink
                    to="/add-faq"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <HelpOutlinedIcon sx={{ mr: "10px" }} />
                        Add New FAQ Data
                    </Box>
                </StyledLink>
            </Box>
        </Box>

        <Box mt="30px">
            
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            { data &&
                data.map((faq) => (
                <Accordion key={faq.id} defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            {faq.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" gap="100px" justifyContent="space-between" alignItems="center">
                            <Typography>
                                {faq.answer}
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Link to={`/edit-faq/${faq.faqID}`}>   
                                    <IconButton>
                                        <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                                    </IconButton>
                                </Link>
                                <IconButton onClick={() => {deleteData(faq.faqID)}}>
                                    <DeleteOutlinedIcon  />
                                </IconButton>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                ))
                
            }
        </Box>
    </Box>
}
 
export default FAQ;