import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Header from '../../components/global/Header';
import axios from 'axios';
import useFetch from '../../hooks/useFetch';
import { toast } from 'react-toastify';

const ConsultantRequests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);

  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/marketers/consultant-requests`
  );

  const approveRequest = async (transactionID, email) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/marketers/approve-consultant-request?transactionID=${transactionID}&email=${email}`,
      { withCredentials: true }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const declineRequest = async (transactionID, email) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/marketers/decline-consultant-request?transactionID=${transactionID}&email=${email}`,
      { withCredentials: true }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const pendRequest = async (transactionID, email) => {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/marketers/pend-consultant-request?transactionID=${transactionID}&email=${email}`,
      { withCredentials: true }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const deleteRequest = async (transactionID, email) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/marketers/delete-consultant-request?transactionID=${transactionID}&email=${email}`,
      { withCredentials: true }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  const columns = [
    {
      field: 'transactionID',
      headerName: 'Transaction ID',
      flex: 0,
      cellClassName: 'disabled',
    },
    {
      field: 'subDate',
      headerName: 'Transaction Date',
      flex: 1.4,
      cellClassName: 'name-column--cell',
      renderCell: (params) => {
        var dateString = new Date(params.row.subDate).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        var date = `${day}, ${day2} ${month}, ${year}`;
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Typography>{date}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      flex: 1.4,
      cellClassName: 'name-column--cell',
      renderCell: (params) => {
        var dateString = new Date(params.row.expiryDate).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        var date = `${day}, ${day2} ${month}, ${year}`;
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Typography>{date}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'fullName',
      headerName: 'Marketer Name',
      flex: 2,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Marketer Email',
      flex: 2,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    {
      headerName: 'Actions',
      flex: 1.5,
      align: 'left',
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            {params.row.status === 'Pending' ? (
              <>
                <IconButton
                  onClick={() => {
                    approveRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <CheckOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    declineRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </>
            ) : params.row.status === 'Confirmed' ? (
              <>
                <IconButton
                  onClick={() => {
                    declineRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <CancelOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    pendRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <PendingOutlinedIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    approveRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <CheckOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    pendRequest(params.row.transactionID, params.row.email);
                  }}
                >
                  <PendingOutlinedIcon />
                </IconButton>
              </>
            )}
            {params.row.status === 'Declined' ? (
              <IconButton
                onClick={() => {
                  deleteRequest(params.row.transactionID, params.row.email);
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            ) : undefined}
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="CONSULTANT REQUESTS"
          subtitle="Here are the Requests by Maketers to become Consultant via the marketer dashboard. Be sure to confir payment before approval."
        />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .disabled': {
            width: '0px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            margin: '0 0 10px 0',
          },
          '& MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {loading && <div style={{ color: 'red !important', fontSize: '20px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '20px' }}>{error}</div>}
        {data && (
          <DataGrid
            checkboxSelection
            components={{ Toolbar: GridToolbar }}
            rows={data}
            columns={columns}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConsultantRequests;
