import { Box, Button, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/global/Header';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

axios.defaults.withCredentials = true;

const AddTeamMember = () => {
  const isNoneMobile = useMediaQuery('(min-width:600px)');
  const history = useNavigate();
  const [coverImage, setCoverImage] = useState();

  const initialValues = {
    fullName: '',
    access: '',
    post: '',
    email: '',
    contact: '',
    linkedin: '',
    instagram: '',
    password: '',
    confirmPassword: '',
  };

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Full name is required'),
    access: yup.string().required('Access Level is required'),
    post: yup.string().required('Position is required'),
    email: yup.string().email('invalid email').required('Email is required'),
    contact: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Admin contact is required'),
    linkedin: yup.string().required('Linkedin profile link is required'),
    instagram: yup.string().required('Instagram profile link is required'),
    password: yup.string().required('Account Password is required').min(8),
    confirmPassword: yup
      .string()
      .required('Confirm Account Password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('coverImage', coverImage);
    formData.append('fullName', values.fullName);
    formData.append('post', values.post);
    formData.append('access', values.access);
    formData.append('email', values.email);
    formData.append('contact', values.contact);
    formData.append('linkedin', values.linkedin);
    formData.append('instagram', values.instagram);
    formData.append('password', values.password);

    console.log(...formData);
    axios
      .post(`${process.env.REACT_APP_API_URL}/team/post-team`, formData, { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          console.log('Successfully published data');
        } else {
          console.log('Failed');
        }
      })
      .then(() => history('/team'))
      .catch((err) => console.log(err));
  };

  return (
    <Box m="20px">
      <Box>
        <Header title="ADD NEW ADMIN" subtitle="Create a new Admin Profile" />
      </Box>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': {
                  gridColumn: isNoneMobile ? undefined : 'span 4',
                },
              }}
              m="40px 0 0 0"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullName}
                name="fullName"
                error={!!touched.fullName && !!errors.fullName}
                helperText={touched.fullName && errors.fullName}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Position"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.post}
                name="post"
                error={!!touched.post && !!errors.post}
                helperText={touched.post && errors.post}
                sx={{ gridColumn: 'span 2' }}
              />
              <Box
                sx={{
                  gridColumn: 'span 2',
                  backgroundColor: 'transparent',
                  position: 'relative',
                }}
              >
                <InputLabel
                  id="select"
                  sx={{
                    position: 'absolute',
                    zIndex: '1',
                    marginTop: '2px',
                    paddingLeft: '10px',
                  }}
                >
                  Select Access Level
                </InputLabel>
                <Select
                  fullWidth
                  variant="filled"
                  labelId="select"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.access}
                  name="access"
                  error={!!touched.access && !!errors.access}
                  sx={{
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    zIndex: '111',
                    top: '0px',
                    height: '50px',
                  }}
                >
                  <MenuItem value="Administrator">Administrator</MenuItem>
                  <MenuItem value="Software Developer">Software Developer</MenuItem>
                  <MenuItem value="Board Member">Board Member</MenuItem>
                </Select>
              </Box>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="LinkedIn Profile Link"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.linkedin}
                name="linkedin"
                error={!!touched.linkedin && !!errors.linkedin}
                helperText={touched.linkedin && errors.linkedin}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Instagram Profile Link"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.instagram}
                name="instagram"
                error={!!touched.instagram && !!errors.instagram}
                helperText={touched.instagram && errors.instagram}
                sx={{ gridColumn: 'span 2' }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Account Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: 'span 2' }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Confirm Account Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="file"
                label="Official Passport"
                onBlur={handleBlur}
                onChange={handleCoverImageChange}
                name="coverImage"
                sx={{ gridColumn: 'span 2' }}
                required
              />
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Create Admin Profile
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AddTeamMember;
