import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import useFetchDataCredential from "../../hooks/useFetchDataCredential";


const RowTwoTransactionHistory = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {data, loading, error} = useFetchDataCredential(`${process.env.REACT_APP_API_URL}/dashboard/get-recent-pending-trans`);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    return <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        overflow="auto"
    >
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
        >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Recent Transactions
            </Typography>
        </Box>
            {data && data.sort((a, b) => b.id - a.id).map((transaction, i) => {
                var dateString = new Date(transaction.subDate).toString();
                var splittedDateString = dateString.split(' ');
                var day = splittedDateString[0];
                var day2 = splittedDateString[1];
                var month = splittedDateString[2];
                var year = splittedDateString[3];
                var subDate = `${day}, ${day2} ${month}, ${year}`;
                return (
            
                <Box
                    key={`${transaction.txId}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                >
                    <Box>
                        <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                        >
                        {transaction.plan} Plan
                        </Typography>
                        <Typography color={colors.grey[100]}>
                        {transaction.email}
                        </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>{subDate}</Box>
                    <Box
                        backgroundColor={colors.greenAccent[500]}
                        p="5px 10px"
                        borderRadius="4px"
                    >
                        {formatter.format(transaction.amount)}
                    </Box>
                </Box>
            )})}
            {loading && <div style={{color:"red !important", fontSize:"25px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"25px"}}>{error}</div>}
        <Box sx={{display: "flex", justifyContent: "center", padding: "10px"}}>
            <Link to="/user-transactions"
                style={{
                    color: "#fff", fontSize: "18px",
                    textDecoration: 'none', fontWeight:"600"
                }}
            >View All</Link>
        </Box>
    </Box>
}
 
export default RowTwoTransactionHistory;