import { Box, Typography, IconButton, useTheme} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import styled from "styled-components";

import axios from "axios";
import useFetch from "../../hooks/useFetch";
import { toast } from "react-toastify";

const Posts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/posts/get-all-posts`);
    const successNotification = (message) => toast.success(message);
    const errorNotification = (message) => toast.error(message);

    const deleteData = async (id) => {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/posts/delete-post/${id}`);
        const response = res.data;
        if (res.status === 200) {
          successNotification(response.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          errorNotification(response.message);
        }
    };

    const columns = [
        {
            field: "coverImage",
            headerName: "Project Image",
            flex: 1,
            renderCell: ({ row : { coverImage }}) => {
                return (
                    <Box>
                        <img 
                            height="30px"
                            width="30px"
                            src={`${process.env.REACT_APP_IMG_BASEURL}/posts/${coverImage}`} 
                            alt="" 
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                )
            }
        },
        {
            field: "postTitle",
            headerName: "Post Title",
            flex: 1,
        },
        {
            field: "post",
            headerName: "Post Content",
            flex: 1,
            renderCell: ({ row : { post }}) => {
                return (
                    <Box
                    >
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {
                                post.substring(0, 30)
                            }...
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            renderCell: ({ row : { category }}) => {
                return (
                    <Box
                        width="60%"
                        m="0 0 0 0"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            category === "Learn" ? colors.redAccent[700] : 
                            category === "Industry News" ? colors.greenAccent[700] :
                            colors.blueAccent[700]
                        }
                        borderRadius = "4px"
                    >
                        <PendingOutlinedIcon />
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {category}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            headerName: "Actions",
            flex: 0.6,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`${process.env.REACT_APP_WEBSITE_URL}/post-details/${params.row.postUrl}`} target="_blank">   
                            <IconButton>
                                <RemoveRedEyeOutlined sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <Link to={`/edit-post/${params.row.postID}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteData(params.row.id)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    const StyledLink = styled(Link)`
        text-decoration: none;
    `;

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="BLOG POSTS" subtitle="List of post contents as published on the website"  />

            <Box>
                <StyledLink
                    to="/add-post"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <WorkOutlinedIcon sx={{ mr: "10px" }} />
                        Add A NEW POST
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="30px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && <DataGrid 
                checkboxSelection
                rows={data}
                columns={columns}
                components={{Toolbar: GridToolbar}}
                getRowId = {
                    (data) => {
                        return data.postID;
                      }
                }
            />}
        </Box>
    </Box>
}
 
export default Posts;