import { Box, CardContent, Typography, Card, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import Header from '../../components/global/Header';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const EnquiryDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enquiryID } = useParams();
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/listings/enquiry/${enquiryID}`
  );

  let date;
  if (data) {
    var dateString = new Date(data.date_added).toString();
    var splittedDateString = dateString.split(' ');
    var day = splittedDateString[0];
    var day2 = splittedDateString[1];
    var month = splittedDateString[2];
    var year = splittedDateString[3];
    date = `${day}, ${day2} ${month}, ${year}`;
  }

  return (
    <Box m="20px">
      <Box>
        <Header
          title={`Enquiry - ${data ? data.title : 'Enquiry Details'}`}
          subtitle="Here is the Details for an Enquiry Made For The Sale"
        />
      </Box>

      <Box marginTop="70px" display="flex" justifyContent="center" alignItems="center">
        <Box backgroundColor={colors.primary[400]} width="42%">
          {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
          {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
          {data && (
            <Card
              sx={{
                background: `${colors.primary[400]} !important`,
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 5px 12px 5px #000c02d8 !important;',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '30px',
                }}
              >
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography sx={{ fontSize: '20px' }} variant="h5">
                    Name: {data.fullName}
                  </Typography>
                  <Typography
                    sx={{
                      color: `${colors.grey[300]}`,
                      border: `1px solid ${colors.grey[600]}`,
                      display: 'inline-block',
                      padding: '2px 10px',
                      margin: '3px',
                    }}
                  >
                    Enquired On: {date}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
                    Making Enquiries For: {data.title}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `${colors.primary[500]} !important`,
                  width: '100%',
                  padding: '13px 40px',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Email:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.email}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>Phone Number:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.phone}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>From:</Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.country}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  padding="5px"
                  sx={{ border: `1px dashed ${colors.grey[600]}`, margin: '5px 0' }}
                >
                  <Typography sx={{ color: `${colors.grey[200]}` }}>
                    Heard about GTMPM through:
                  </Typography>
                  <Typography sx={{ color: `${colors.grey[200]}`, fontWeight: '600' }}>
                    {data.source}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `${colors.primary[400]} !important`,
                  width: '100%',
                  padding: '13px',
                }}
              >
                <Typography
                  sx={{
                    color: `${colors.grey[100]}`,
                    margin: '3px',
                  }}
                  variant="h5"
                >
                  Enquiry Message
                </Typography>
                <Typography
                  sx={{
                    color: `${colors.grey[100]}`,
                    border: `1px solid ${colors.grey[300]}`,
                    display: 'inline-block',
                    padding: '2px 10px',
                    margin: '3px',
                  }}
                >
                  <b>{data.message}</b>
                </Typography>

                <br />
                <Box display="flex" justifyContent="flex-end" padding="5px">
                  <Link
                    to={`/record-sale?enquiryID=${data.enquiryID}&listingID=${data.listingID}&title=${data.title}&fullName=${data.fullName}&email=${data.email}&phone=${data.phone}`}
                  >
                    <Typography
                      sx={{
                        backgroundColor: `${colors.greenAccent[500]}`,
                        color: colors.grey[900],
                        border: `1px solid ${colors.grey[600]}`,
                        display: 'inline-block',
                        padding: '5px 15px',
                        margin: '3px',
                        fontSize: '17px',
                        fontWeight: 'bold',
                      }}
                    >
                      Record Sale
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EnquiryDetails;
