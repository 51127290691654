import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/global/Header';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import axios from 'axios';
import useFetch from '../../hooks/useFetch';

const Shop = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/listings/get-all-listings`
  );
  if (error) {
    errorNotification(error);
  }

  const deleteData = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/listings/delete-listing/${id}`)
      .then((res) => {
        if (res.status === 200) {
          successNotification(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1300);
        } else {
          errorNotification(res.data.message);
        }
      })
      .catch((err) => {
        errorNotification(err.message);
      });
  };

  const columns = [
    {
      field: 'listingID',
      headerName: 'Listing ID',
      //   flex: 0,
      cellClassName: 'disabled',
    },
    {
      field: 'coverImage',
      headerName: 'Cover Image',
      flex: 0.6,
      renderCell: ({ row: { coverImage } }) => {
        return (
          <Box>
            <img
              height="30px"
              width="30px"
              src={`${process.env.REACT_APP_IMG_BASEURL}/shop/${coverImage}`}
              alt=""
              style={{ cursor: 'pointer' }}
            />
          </Box>
        );
      },
    },
    {
      field: 'listingTitle',
      headerName: 'Item Title',
      flex: 0.8,
    },
    {
      field: 'listingDetails',
      headerName: 'Item Details',
      flex: 1.5,
      renderCell: ({ row: { listingDetails } }) => {
        return (
          <Box>
            <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
              {listingDetails.substring(0, 50)}...
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      renderCell: ({ row: { price } }) => (
        <Typography color={colors.greenAccent[500]}>{formatter.format(price)}</Typography>
      ),
    },
    {
      headerName: 'Actions',
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="4px"
          >
            <Link
              to={`/listing-enquiries/${params.row.listingID}`}
              style={{
                color: `${colors.greenAccent[300]}`,
                textDecoration: 'none',
                fontSize: '15px',
              }}
            >
              View Requests
            </Link>
            <Link to={`/listing-details/${params.row.listingID}`}>
              <IconButton>
                <RemoveRedEyeOutlined sx={{ color: `${colors.grey[100]} !important` }} />
              </IconButton>
            </Link>
            <Link to={`/edit-listing/${params.row.listingID}`}>
              <IconButton>
                <EditOutlinedIcon sx={{ color: `${colors.grey[100]} !important` }} />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                deleteData(params.row.listingID);
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const StyledLink = styled(Link)`
    text-decoration: none;
  `;

  return (
    <Box m="20px">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="GTMPM STORE"
          subtitle="List of the IUtems available for sales on GTMPM Store (Ordered By Recent)"
        />

        <Box>
          <StyledLink to="/add-listing">
            <Box
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                textDecoration: 'none !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '12px 20px',
                borderRadius: '4px',
              }}
            >
              <ShoppingCartOutlinedIcon sx={{ mr: '10px' }} />
              List A NEW Item
            </Box>
          </StyledLink>
        </Box>
      </Box>
      <Box
        m="30px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .disabled': {
            width: '0px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            margin: '0 0 10px 0',
          },
          '& MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {data && (
          <DataGrid
            checkboxSelection
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(data) => data.listingID}
          />
        )}
        {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
      </Box>
    </Box>
  );
};

export default Shop;
