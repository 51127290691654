import RowTwoRevenue from "./RowTwoRevenue";
import RowTwoTransactionHistory from "./RowTwoTransactionHistory";

const RowTwo = () => {
    
    return <>

        <RowTwoRevenue />
        
        <RowTwoTransactionHistory />
    </>
}
 
export default RowTwo;