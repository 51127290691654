import { Box, Button, TextField } from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";

import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const initialValues = {
    title: "",
    min: "",
    max: "",
    roi: "",
}

const validationSchema = yup.object().shape({
    title: yup.string().required("Package Titlee is required"),
    min: yup.string().required("Minimum Amount is required"),
    max: yup.string().required("Maximum Amount is required"),
    roi: yup.string().required("Provide the Return on Investment"),
})

const AddInvestmentData = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const history = useNavigate();

    const handleFormSubmit = (values) => {
        const sendRequest = async () => {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/investments/post-investment-data`, {
                title: values.title,
                min: values.min,
                max: values.max,
                roi: values.roi,
            }, { withCredentials: true}).catch(err => console.log(err));
            const data = await res.data;
            console.log(data);
            return data;
        }
        sendRequest().then(()=>history("/investments-data"));
    }

    return <Box m="20px">
        <Box>
            <Header title="ADD NEW INVESTMENT PLAN" subtitle="Create a new Investment Package Here" />    
        </Box>
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Package Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.title}
                                name="title"
                                error={!!touched.title && !!errors.title}
                                helperText={touched.title && errors.title}
                                sx={{ gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Minimum Amount"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.min}
                                name="min"
                                error={!!touched.min && !!errors.min}
                                helperText={touched.min && errors.min}
                                sx={{ gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Maximum Amount"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.max}
                                name="max"
                                error={!!touched.max && !!errors.max}
                                helperText={touched.max && errors.max}
                                sx={{ gridColumn: "span 2"}}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Return on Investment"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.roi}
                                name="roi"
                                error={!!touched.roi && !!errors.roi}
                                helperText={touched.roi && errors.roi}
                                sx={{ gridColumn: "span 2"}}
                            />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Create Investment Plan
                            </Button>
                        </Box>
                        
                    </form>
                )
            }
        </Formik>
    </Box>
}
 
export default AddInvestmentData;