import { Box, CardContent, Typography, Card, useTheme, Badge } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const ListingDetails = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { listingID } = useParams();
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/listings/listing/${listingID}`);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    let newArray1;
    let newArray2;

    if(data) {
        const array1 = data.features;
        const array2 = data.images;
        newArray1 = array1.split(', ');
        newArray2 = array2.split(', ');
    } else {
        newArray1 = undefined;
        newArray2 = undefined;
    }

    return <Box m="20px">
        <Box>
            <Header 
                title={`Listing - ${data ? data.listingTitle : 'Listing Details'}`} 
                subtitle="Here is the Details for a Page" 
            />    
        </Box>

        <Box
            marginTop="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="55%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "30px",
                            }}
                        >   
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >   
                                <Link to={`${process.env.REACT_APP_IMG_BASEURL}/shop/${data.coverImage}`}>
                                    <img
                                        alt={data.coverImage}
                                        src={`${process.env.REACT_APP_IMG_BASEURL}/shop/${data.coverImage}`}
                                        width="100%" height="250"
                                    />
                                </Link>
                            </StyledBadge>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">
                                    {data.listingTitle}
                                </Typography>
                                <Typography sx={{ color: `${colors.grey[200]}` }}>
                                    {data.listingDetails}
                                </Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography
                                    variant="h5"
                                    sx={{color:colors.greenAccent[500]}}
                                >
                                    {formatter.format(data.price)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Property Type: <b>{data.propertyType}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Property Class: <b>{data.propertyClass}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Offer Type: <b>{data.offerType}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Rooms: <b>{data.bedrooms}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Birthrooms: <b>{data.bathrooms}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Property Size: <b>{data.propertySize}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Neighborhood: <b>{data.neighborhood}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                City: <b>{data.city}</b>
                            </Typography>
                            <Typography sx={{
                                color: `${colors.grey[300]}`,
                                border: `1px solid ${colors.grey[600]}`,
                                display: 'inline-block',
                                padding: "2px 10px",
                                margin: "3px"
                            }}>
                                Country: <b>{data.country}</b>
                            </Typography>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.blueAccent[700]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >   
                            <Typography
                                sx={{
                                    color: `${colors.grey[100]}`,
                                    margin: "3px"
                                }}
                                variant="h5"
                            >Features</Typography>
                            {
                                
                                newArray1 && newArray1.map((feat) => (
                                    <Typography sx={{
                                        color: `${colors.grey[100]}`,
                                        border: `1px solid ${colors.grey[300]}`,
                                        display: 'inline-block',
                                        padding: "2px 10px",
                                        margin: "3px"
                                    }}>
                                        <b>{feat}</b>
                                    </Typography>
                                ))

                                
                            }
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[400]} !important`, 
                                width: "100%",
                                padding: "13px",
                           }} 
                        >   
                            <Typography
                                sx={{
                                    color: `${colors.grey[100]}`,
                                    margin: "3px"
                                }}
                                variant="h5"
                            >Item Images</Typography>
                            {
                                
                                newArray2 && newArray2.map((image) => (
                                    <Box sx={{
                                        border: `1px solid ${colors.grey[300]}`,
                                        display: 'inline-block',
                                        padding: "5px",
                                        margin: "0.5%",
                                        width: "19%"
                                    }}>
                                        <Link to={`${process.env.REACT_APP_IMG_BASEURL}/shop/${image}`}>
                                            <img
                                                alt={image}
                                                src={`${process.env.REACT_APP_IMG_BASEURL}/shop/${image}`}
                                                width="100%" height="75"
                                            />
                                        </Link>
                                    </Box>
                                ))

                                
                            }
                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default ListingDetails;