import { Box, CardContent, Typography, Card, useTheme, Badge, Avatar } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import { Link, useParams } from "react-router-dom";
import useFetchCredential from "../../hooks/useFetchCredential";



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

const UserProfile = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { userID } = useParams();
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/users/profile/${userID}`);

    let date;

    if (data) {
        var dateString = new Date(data.date).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        date = `${day}, ${day2} ${month}, ${year}`;
    }

    return <Box m="20px">
        <Box>
            <Header 
                title={`${data ? data.fName + " " + data.lName +  "'s Profile" : 'User Profile'}`} 
                subtitle="Here is the User Profile Page" 
            />    
        </Box>

        <Box
            marginTop="60px"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >   
            <Box
                backgroundColor= {colors.primary[400]}
                width="30%"
            >
                {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
                {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
                {data &&
                    <Card 
                        sx={{ 
                            background:`${colors.primary[400]} !important`, 
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center', 
                            alignItems: "center",
                            boxShadow: "1px 5px 12px 5px #000c02d8 !important;"
                        }}
                    >   
                        
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                textAlign:'center', 
                                alignItems:'center', 
                                justifyContent:'center',
                                padding: "30px",
                            }}
                        >   
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Link to={`${process.env.REACT_APP_IMG_BASEURL}/users/${data.coverImage}`} ><Avatar
                                    alt={`Global Triumph user - ${data.fName} ${data.lName}`}
                                    src={`${process.env.REACT_APP_IMG_BASEURL}/users/${data.coverImage}`}
                                    sx={{ width: 150, height: 150 }}
                                /></Link>
                            </StyledBadge>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography variant="h5">
                                    {`${data.fName} ${data.lName}`}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: `${colors.grey[200]}`
                                    }}
                                >
                                    {data.status}
                                </Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: `${
                                                data.subscribedTo === "" ? colors.redAccent[200]
                                                : colors.greenAccent[500]
                                            }`
                                    }}
                                >
                                    Joined on: {date}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                           sx={{
                                background:`${colors.primary[500]} !important`, 
                                width: "100%",
                                padding: "13px 40px",
                           }} 
                        >
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>User Email:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.email}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Phone Number:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.phone}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" padding="5px" sx={{border: `1px dashed ${colors.grey[600]}`, margin:"5px 0"}}>
                                <Typography sx={{color: `${colors.grey[200]}`}}>Address:</Typography>
                                <Typography sx={{color: `${colors.grey[200]}`, fontWeight:"600"}}>{data.address}, {data.city}</Typography>
                            </Box>

                        </Box>
                    </Card>
                }   
            </Box>
        </Box>


    </Box>
}
 
export default UserProfile;