import { Box, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import Header from "../../components/global/Header";
import styled from "styled-components";

import axios from "axios";
import useFetch from "../../hooks/useFetch";

axios.defaults.withCredentials = true;

const StyledLink = styled(Link)`
    text-decoration: none;
`;

const Visas = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/visas/get-visas`);

    const deleteVisaRecord = (visaID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/visas/delete-visa/${visaID}`, { withCredentials: true})
        .then(window.location.reload());;
    };

    const columns = [
        {
            field: "visaID",
            headerName: "ID",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "coverImage",
            headerName: "Visa",
            flex: 1,
            renderCell: ({ row : { coverImage }}) => {
                return (
                    <Box>
                        <img 
                            height="30px"
                            width="30px"
                            src={`${process.env.REACT_APP_IMG_BASEURL}/visas/${coverImage}`} 
                            alt="" 
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                )
            }
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1
        },
        {
            headerName: "Actions",
            flex: 1,
            align: "left",
            // field: "id",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        
                        <Link to={`/edit-visa-record/${params.row.visaID}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteVisaRecord(params.row.visaID)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="VISAS" subtitle="Here are the past Visa works as posted on the website"  />
            <Box>
                <StyledLink
                    to="/add-visa-record"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <ReceiptOutlinedIcon sx={{ mr: "10px" }} />
                        Add New Visa Record
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="40px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                }
            }}
        >   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
            {data && 
                <DataGrid rows={data} columns={columns} 
                    // getRowId = {(data) => {
                    //     return data.id;
                    // }} 
                    // key = {(data) => {
                    //     return data.id;
                    // }}
                />
            }
            
        </Box>
    </Box>
}
 
export default Visas;