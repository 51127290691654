import { Box, Button, InputLabel, Input } from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/global/Header';

import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.defaults.withCredentials = true;

const RecordSale = () => {
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);
  const isNoneMobile = useMediaQuery('(min-width:600px)');
  const history = useNavigate();
  const [transactionReceipt, setTransactionReceipt] = useState();
  const [purchaseInvoice, setPurchaseInvoice] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const enquiryID = searchParams.get('enquiryID');
  const listingID = searchParams.get('listingID');
  const title = searchParams.get('title');
  const fullName = searchParams.get('fullName');
  const email = searchParams.get('email');
  const phone = searchParams.get('phone');

  const initialValues = { amount: '' };

  const validationSchema = yup
    .object()
    .shape({ amount: yup.string().required('How much is this proerty being sold') });

  const handleTransactionReceipt = (e) => {
    setTransactionReceipt(e.target.files[0]);
  };
  const handlePurchaseInvoice = (e) => {
    setPurchaseInvoice(e.target.files[0]);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('transactionReceipt', transactionReceipt);
    formData.append('purchaseInvoice', purchaseInvoice);
    formData.append('listingID', listingID);
    formData.append('enquiryID', enquiryID);
    formData.append('fullName', fullName);
    formData.append('title', title);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('amount', values.amount);

    console.log(...formData);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/listings/record-sale`,
      formData,
      {
        withCredentials: true,
      }
    );
    const response = res.data;
    if (res.status !== 200) {
      errorNotification(response.message);
    } else {
      successNotification(response.message);
      setTimeout(() => history('/sales'), 3000);
    }
    console.log(('response', response));
  };

  return (
    <Box m="20px">
      <Box>
        <Header title={`RECORDING SALE FOR - ${title}`} subtitle="Create a new Admin Profile" />
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                '& > div': {
                  gridColumn: isNoneMobile ? undefined : 'span 4',
                },
              }}
              m="40px 0 0 0"
            >
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="title">Item</InputLabel>
                <Input fullWidth type="text" value={title} disabled />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="listingTitle">Buyer</InputLabel>
                <Input fullWidth type="text" value={fullName} disabled />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="email">Buyer Email</InputLabel>
                <Input fullWidth type="text" value={email} disabled />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="phone">Buyer Phone</InputLabel>
                <Input fullWidth type="text" value={phone} disabled />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="transactionReceipt">Attach Payment Receipt</InputLabel>
                <input
                  type="file"
                  name="transactionReceipt"
                  onChange={handleTransactionReceipt}
                  required
                  style={{ borderBottom: '1px solid #eee', width: '100%' }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="purchaseInvoice">Attach Sale Invoice</InputLabel>
                <input
                  type="file"
                  name="purchaseInvoice"
                  onChange={handlePurchaseInvoice}
                  required
                  style={{ borderBottom: '1px solid #eee', width: '100%' }}
                />
              </Box>
              <Box sx={{ gridColumn: 'span 2' }}>
                <InputLabel id="amount">Total Amount</InputLabel>
                <Input
                  fullWidth
                  type="number"
                  value={values.amount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="amount"
                  error={!!touched.amount && !!errors.amount}
                  helperText={touched.amount && errors.amount}
                />
                <ErrorMessage name="amount" component="span" className="error" />
              </Box>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Record Sale to Database
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default RecordSale;
