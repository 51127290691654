import { Box, IconButton, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Header from '../../components/global/Header';
import { Link } from 'react-router-dom';

import axios from 'axios';
import useFetchCredential from '../../hooks/useFetchCredential';

const Marketers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/marketers/get-marketers`
  );

  console.log(data);

  const deleteUser = (userID) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/marketers/delete-marketer/${userID}`)
      .then(window.location.reload());
  };

  const columns = [
    {
      field: 'userID',
      headerName: 'User ID',
      flex: 1,
    },
    {
      field: 'fName',
      headerName: 'First Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'lName',
      headerName: 'First Name',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'level',
      headerName: 'Level',
      flex: 1,
    },
    {
      headerName: 'Actions',
      flex: 0.8,
      align: 'left',
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Link to={`/marketer-profile/${params.row.userID}`}>
              <IconButton>
                <RemoveRedEyeOutlined sx={{ color: `${colors.grey[100]} !important` }} />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                deleteUser(params.row.userID);
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="MARKETERS" subtitle="Manage the List of GTMPM Marketers Here" />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            margin: '0 0 10px 0',
          },
        }}
      >
        {loading && <div style={{ color: 'red !important', fontSize: '20px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '20px' }}>{error}</div>}
        {data && (
          <DataGrid
            checkboxSelection
            rows={data}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(data) => {
              return data.userID;
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Marketers;
