import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Header from '../../components/global/Header';
import axios from 'axios';
import useFetch from '../../hooks/useFetch';

const PurchaseRequests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/listings/purchase-requests`
  );

  const deleteData = (enquiryID) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/listings/delete-enquiry/${enquiryID}`)
      .then(window.location.reload());
  };

  const columns = [
    {
      field: 'enquiryID',
      headerName: 'Enquiry ID',
      flex: 0,
      cellClassName: 'disabled',
    },
    {
      field: 'date_added',
      headerName: 'Enquiry Date',
      flex: 0.7,
      cellClassName: 'name-column--cell',
      renderCell: (params) => {
        var dateString = new Date(params.row.date_added).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        var date = `${day}, ${day2} ${month}, ${year}`;
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Typography>{date}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Property',
      flex: 2,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'fullName',
      headerName: 'Enquired By',
      flex: 1.5,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'phone',
      headerName: 'Contact Phone',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Contact Email',
      flex: 1,
    },
    {
      headerName: 'Actions',
      flex: 0.7,
      align: 'left',
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Link to={`/enquiry-details/${params.row.enquiryID}`}>
              <IconButton>
                <RemoveRedEyeOutlined sx={{ color: `${colors.grey[100]} !important` }} />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                deleteData(params.row.enquiryID);
              }}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="PURCHASE REQUESTS"
          subtitle="Here are the Enquries Made by Potential Clients from the website"
        />
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .disabled': {
            width: '0px !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            margin: '0 0 10px 0',
          },
          '& MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {loading && <div style={{ color: 'red !important', fontSize: '20px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '20px' }}>{error}</div>}
        {data && (
          <DataGrid
            checkboxSelection
            components={{ Toolbar: GridToolbar }}
            rows={data}
            columns={columns}
          />
        )}
      </Box>
    </Box>
  );
};

export default PurchaseRequests;
