import { Box, Button, InputLabel, Input } from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/global/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.withCredentials = true;

const initialValues = {
    question: "",
    answer: "",
}

const adminSchema = yup.object().shape({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
})


const AddFAQ = () => {
    const isNoneMobile = useMediaQuery("(min-width:600px)");
    const history = useNavigate();

    const handleFormikSubmit = (values) => {
        const sendRequest = async () => {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/faqs/post-faq`, {
                question: values.question,
                answer: values.answer
            }, { withCredentials: true}).catch(err => console.log(err));
            const data = await res.data;
            console.log(data);
            return data;
        }
        sendRequest().then(()=>history("/faq"));
    }

    return <Box m="20px">
        <Box>
            <Header title="ADD NEW FAQ" subtitle="Add a New FAQ Item" />    
        </Box>

        <Formik
            onSubmit={handleFormikSubmit}
            initialValues={initialValues}
            validationSchema={adminSchema}
        >
            {
                ({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {
                                    gridColumn: isNoneMobile ? undefined : "span 4"
                                }
                            }}
                            m="40px 0 0 0"
                        >
                            <Box sx={{ gridColumn: "span 4"}}>
                                <InputLabel id="question">FAQ Question</InputLabel>
                                <Input
                                    fullWidth 
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.question}
                                    name="question"
                                    error={!!touched.question && !!errors.question}
                                />
                            </Box>
                            
                            <Box sx={{ gridColumn: "span 4"}}>
                                    <InputLabel id="answer">FAQ Answer</InputLabel>
                                <Input
                                    labelid="answer"
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    multiline
                                    rows="3"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.answer}
                                    name="answer"
                                    error={!!touched.answer && !!errors.answer}
                                    sx={{ gridColumn: "span 2"}}
                                />
                            </Box>
                           
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                        >
                            <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600"
                                }}
                            >
                                Publish FAQ Item
                            </Button>
                        </Box>
                    </form>
                )
            }
        </Formik>
    </Box>
}
 
export default AddFAQ;