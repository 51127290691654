import { Box, Typography, IconButton, useTheme} from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import styled from "styled-components";

import axios from "axios";
import useFetch from "../../hooks/useFetch";

const EventsGallery = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetch(`${process.env.REACT_APP_API_URL}/gallery/get-all-gallery`);

    const deleteData = (projectID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/gallery/delete-gallery/${projectID}`)
        .then(window.location.reload());
    };

    const columns = [
        {
            field: "eventID",
            headerName: "Event ID",
            flex: 0.6,
        },
        {
            field: "eventCoverImage",
            headerName: "Event Cover Image",
            flex: 0.6,
            renderCell: ({ row : { eventCoverImage }}) => {
                return (
                    <Box>
                        <img 
                            height="30px"
                            width="30px"
                            src={`${process.env.REACT_APP_IMG_BASEURL}/gallery/${eventCoverImage}`} 
                            alt="" 
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                )
            }
        },
        {
            field: "eventTitle",
            headerName: "Event Title",
            flex: 1.4,
        },
        {
            field: "eventDetails",
            headerName: "Item Details",
            flex: 2,
            renderCell: ({ row : { eventDetails }}) => {
                return (
                    <Box
                    >
                        <Typography
                            color={colors.grey[100]}
                            sx={{ml: "5px"}}
                        >
                            {
                                eventDetails.substring(0, 50)
                            }...
                        </Typography>
                    </Box>
                )
            }
        },
        {
            headerName: "Actions",
            flex: 0.6,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Link to={`/event-details/${params.row.eventID}`}>   
                            <IconButton>
                                <RemoveRedEyeOutlined sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <Link to={`/edit-event-gallery/${params.row.eventID}`}>   
                            <IconButton>
                                <EditOutlinedIcon sx={{color:`${colors.grey[100]} !important`}} />
                            </IconButton>
                        </Link>
                        <IconButton onClick={() => {deleteData(params.row.eventID)}}>
                            <DeleteOutlinedIcon  />
                        </IconButton>
                    </Box>
                )
            }
        },
    ]

    const StyledLink = styled(Link)`
        text-decoration: none;
    `;

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="GTMPM EVENTS GALLERY" subtitle="List of the Events By GTMPM with Images for the Gallery (Ordered By Recent)"  />

            <Box>
                <StyledLink
                    to="/add-event-gallery"
                >
                    <Box
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            textDecoration: "none !important",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "12px 20px",
                            borderRadius: "4px",
                        }}
                    >
                        <ShoppingCartOutlinedIcon sx={{ mr: "10px" }} />
                        Add Event to Gallery
                    </Box>
                </StyledLink>
            </Box>
        </Box> 
        <Box
            m="30px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >
            {data && <DataGrid 
                checkboxSelection
                rows={data}
                columns={columns}
                components={{Toolbar: GridToolbar}}
                getRowId = {
                    (data) => {
                        return data.eventID;
                      }
                }
                key = {(data) => {
                    return data.eventID;
                  }}
            />}
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
        </Box>
    </Box>
}
 
export default EventsGallery;