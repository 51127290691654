import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/global/Header";
import RowTwo from "../../components/dashboard/RowTwo";
import RowOne from "../../components/dashboard/RowOne";
import RowThree from "../../components/dashboard/RowThree";
import axios from "axios";
axios.defaults.withCredentials = true;

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="DASHBOARD" subtitle="Welcome to Global Triumph Multiconcept PM admin dashboard"  />
            <Box>
                <Button
                    sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    }}
                >
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                    Download Reports
                </Button>
            </Box>
        </Box>
        
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
            mt="30px"
        >
            <RowOne />

            <RowTwo />

            <RowThree />
        </Box> 
    </Box>
}

export default Dashboard;