import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import PersonPincon from '@mui/icons-material/PersonPin';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StatBox from './StatBox';
import useFetchDataCredential from '../../hooks/useFetchDataCredential';
import { formatter } from '../../utils';

const RowOne = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    data: userCountData,
    loading: userCountLoading,
    error: userCountError,
  } = useFetchDataCredential(`${process.env.REACT_APP_API_URL}/dashboard/get-users-count`);
  const {
    data: marketerCountData,
    loading: marketerCountLoading,
    error: marketerCountError,
  } = useFetchDataCredential(`${process.env.REACT_APP_API_URL}/dashboard/get-marketers-count`);
  const {
    data: amountInvestedData,
    loading: amountInvestedLoading,
    error: amountInvestedError,
  } = useFetchDataCredential(`${process.env.REACT_APP_API_URL}/dashboard/get-amount-invested`);
  const {
    data: investedClientData,
    loading: investedClientLoading,
    error: investedClientError,
  } = useFetchDataCredential(`${process.env.REACT_APP_API_URL}/dashboard/get-invested-clients`);

  return (
    <>
      {/* ROW 1 */}
      {userCountData && (
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={userCountData}
            subtitle="Registered Users"
            progress="0.75"
            increase="+14%"
            icon={<PersonAddIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
          />
        </Box>
      )}
      {userCountLoading && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>
      )}
      {userCountError && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>{userCountError}</div>
      )}

      {marketerCountData && (
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={marketerCountData}
            subtitle="Registered Marketers"
            progress="0.75"
            increase="+14%"
            icon={<PersonAddIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
          />
        </Box>
      )}
      {marketerCountLoading && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>
      )}
      {marketerCountError && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>{marketerCountError}</div>
      )}

      {amountInvestedData && (
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={formatter.format(amountInvestedData)}
            subtitle="Amount Invested"
            progress="0.50"
            increase="+21%"
            icon={<PointOfSaleIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
          />
        </Box>
      )}
      {amountInvestedLoading && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>
      )}
      {amountInvestedError && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>{amountInvestedError}</div>
      )}

      {investedClientData && (
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={investedClientData}
            subtitle="Investment Clients"
            progress="0.30"
            increase="+5%"
            icon={<PersonPincon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
          />
        </Box>
      )}
      {investedClientLoading && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>Loading...</div>
      )}
      {investedClientError && (
        <div style={{ color: 'red !important', fontSize: '25px' }}>{investedClientError}</div>
      )}
    </>
  );
};

export default RowOne;
