import { Box, Typography, IconButton, useTheme} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/global/Header";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

import axios from "axios";
import useFetchCredential from "../../hooks/useFetchCredential";
axios.defaults.withCredentials = true;

const Withdrawals = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, loading, error} = useFetchCredential(`${process.env.REACT_APP_API_URL}/withdrawals/get-all-withdrawals`);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'NGN',
    });

    const approveWithdrawal = (id) => {
        axios.put(`${process.env.REACT_APP_API_URL}/withdrawals/approve-withdrawal/${id}`, { withCredentials: true})
        .then(window.location.reload());;
    };
    const declineWithdrawal = (id) => {
        axios.put(`${process.env.REACT_APP_API_URL}/withdrawals/decline-withdrawal/${id}`, { withCredentials: true})
        .then(window.location.reload());;
    };

    const columns = [
        {
            field: "txId",
            headerName: "Transaction ID",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Transaction Date",
            flex: 1,
            renderCell: (params) => {
                var dateString = new Date(params.row.date).toString();
                var splittedDateString = dateString.split(' ');
                var day = splittedDateString[0];
                var day2 = splittedDateString[1];
                var month = splittedDateString[2];
                var year = splittedDateString[3];
                var date = `${day}, ${day2} ${month}, ${year}`;
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        <Typography>{date}</Typography>
                    </Box>
                )
            }
        },
        {
            field: "email",
            headerName: "Account Email",
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Transaction Amount",
            flex: 1,
            renderCell: (params) => (
                <Typography
                    color= {colors.greenAccent[500]}
                >
                    { formatter.format(params.row.amount) }
                </Typography>
            )
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: ({ row : { status }}) => {
                return (
                    <Box
                        width="60%"
                        m="0 0 0 0"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            status === "Declined" ? colors.redAccent[500] 
                            : status === "Completed" ? colors.greenAccent[500] 
                            : colors.blueAccent[700]}
                        borderRadius = "4px"
                    >
                        <PendingOutlinedIcon />
                        <Typography
                            color={colors.grey[100]}
                            
                            sx={{ml: "5px"}}
                        >
                            {status}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            headerName: "Actions",
            flex: 0.6,
            align: "left",
            renderCell: (params) => {
                return (
                    <Box
                        m="0 0 0 0"
                        p="5px 0"
                        display="flex"
                        justifyContent="space-between"
                        borderRadius = "4px"
                    >
                        {
                            params.row.status === "Pending" ? 
                                <IconButton onClick={() => {approveWithdrawal(params.row.id)}}>
                                    <CheckOutlinedIcon  />
                                </IconButton>
                            : undefined
                        }
                        {
                            params.row.status === "Pending" ? 
                                <IconButton onClick={() => {declineWithdrawal(params.row.id)}}>
                                    <CancelOutlinedIcon  />
                                </IconButton>
                            : undefined
                        }
                    </Box>
                )
            }
        },
    ]

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="PENDING WITHDRAWALS" subtitle="List of Pending Withdrawal Requests By Users (Ordered By Recent)"  />
        </Box> 
        <Box
            m="30px 0 0 0"
            height="75vh"  
            sx ={{
                "& .MuiDataGrid-root": {
                    border: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderBottom: "none"
                },
                "& .name-column--cell": {
                    color: colors.greenAccent[300]
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontSize: "15px",
                    fontWeight: "bold"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400]
                },
                "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                    margin: "0 0 10px 0"
                },
                "& MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`
                }
            }}
        >
            {data && <DataGrid 
                    checkboxSelection
                    rows={data.filter(data => data.status === "Pending")}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                    getRowId = {
                        (data) => {
                            return data.txId;
                        }
                    }
                />
            }   
            {loading && <div style={{color:"red !important", fontSize:"30px"}}>Loading...</div>}
            {error && <div style={{color:"red !important", fontSize:"30px"}}>{error}</div>}
        </Box>
    </Box>
}
 
export default Withdrawals;