import { Box, Button, Select, MenuItem, InputLabel, Input } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/global/Header';
import { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.css';

import axios from 'axios';
import useFetch from '../../hooks/useFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

axios.defaults.withCredentials = true;

const divElement = document.createElement('div');
divElement.className = styles['text-editor'];

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    [{ direction: 'rtl' }],
  ],
};

const EditLearnPost = () => {
  const isNoneMobile = useMediaQuery('(min-width:600px)');
  const { postID } = useParams();
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}/posts/post-by-id/${postID}`
  );
  const history = useNavigate();
  const [coverImage, setCoverImage] = useState();
  const [post, setPost] = useState('');
  const successNotification = (message) => toast.success(message);
  const errorNotification = (message) => toast.error(message);

  const initialValues = data
    ? {
        postTitle: data.postTitle,
        category: data.category,
      }
    : {
        postTitle: '',
        category: '',
      };

  const validationSchema = yup.object().shape({
    postTitle: yup.string().required('Post Title is required'),
    category: yup.string().required('Select Post Category'),
  });

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('coverImage', coverImage);
    formData.append('postTitle', values.postTitle);
    formData.append('post', post);
    formData.append('category', values.category);
    console.log(...formData);

    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/posts/update-post/${postID}`,
      formData,
      { withCredentials: true }
    );
    const response = res.data;
    if (res.status === 200) {
      successNotification(response.message);
      setTimeout(() => {
        history('/posts');
      }, 1500);
    } else {
      errorNotification(response.message);
    }
  };

  return (
    <Box m="20px">
      <Box>
        <Header
          title="EDIT POST CONTENT"
          subtitle="Edit This Post That Was Already Published to the Website"
        />
      </Box>
      {data && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  '& > div': {
                    gridColumn: isNoneMobile ? undefined : 'span 4',
                  },
                }}
                m="40px 0 0 0"
              >
                <Box sx={{ gridColumn: 'span 4' }}>
                  <InputLabel id="postTitle">Project Title</InputLabel>
                  <Input
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    name="postTitle"
                    value={values.postTitle}
                    error={!!touched.postTitle && !!errors.postTitle}
                    helpertext={touched.postTitle && errors.postTitle}
                    onBlur={handleBlur}
                  />
                </Box>

                <Box
                  sx={{
                    gridColumn: 'span 4',
                    height: '50vh',
                    background: '#fff',
                    color: '#121212',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 20px',
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    value={data.post}
                    onChange={setPost}
                    modules={modules}
                    className="text-editor"
                  />
                </Box>

                <Box
                  sx={{
                    gridColumn: 'span 2',
                    backgroundColor: 'transparent',
                    position: 'relative',
                  }}
                >
                  <InputLabel
                    id="select"
                    sx={{
                      position: 'absolute',
                      zIndex: '1',
                      marginTop: '2px',
                      paddingLeft: '10px',
                    }}
                  >
                    Post Category
                  </InputLabel>
                  <Select
                    fullWidth
                    variant="filled"
                    labelId="select"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.category}
                    name="category"
                    error={!!touched.category && !!errors.category}
                    sx={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      zIndex: '111',
                      top: '0px',
                      height: '50px',
                    }}
                  >
                    <MenuItem value="Learn">Learn</MenuItem>
                    <MenuItem value="Industry News">Industry News</MenuItem>
                    <MenuItem value="Investment Update">Investment Update</MenuItem>
                    <MenuItem value="Visa Opportunity">Visa Opportunity</MenuItem>
                  </Select>
                </Box>

                <Box sx={{ gridColumn: 'span 2' }}>
                  <InputLabel id="coverImage">Cover Image</InputLabel>
                  <Input
                    fullWidth
                    variant="filled"
                    type="file"
                    label="Cover Image"
                    placeholder="Cover Image"
                    onChange={handleCoverImageChange}
                    name="coverImage"
                    sx={{ gridColumn: 'span 2' }}
                    required
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Edit Post Content
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
      {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
    </Box>
  );
};

export default EditLearnPost;
