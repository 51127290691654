import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/global/Header';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import axios from 'axios';
import useFetchCredential from '../../hooks/useFetchCredential';
import { Link } from 'react-router-dom';
import { RemoveRedEyeOutlined } from '@mui/icons-material';

axios.defaults.withCredentials = true;

const Withdrawals = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data, loading, error } = useFetchCredential(
    `${process.env.REACT_APP_API_URL}/withdrawals/get-all-withdrawals`
  );
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN',
  });

  const columns = [
    {
      field: 'txId',
      headerName: 'Transaction ID',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Typography>...{params.row.txId.substring(15, 26)}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Transaction Date',
      flex: 0.8,
      renderCell: (params) => {
        var dateString = new Date(params.row.date).toString();
        var splittedDateString = dateString.split(' ');
        var day = splittedDateString[0];
        var day2 = splittedDateString[1];
        var month = splittedDateString[2];
        var year = splittedDateString[3];
        var date = `${day}, ${day2} ${month}, ${year}`;
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Typography>{date}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: 'By',
      flex: 1,
    },
    {
      field: 'accountName',
      headerName: 'To',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Transaction Amount',
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {formatter.format(params.row.amount)}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: ({ row: { status } }) => {
        return (
          <Box
            width="60%"
            m="0 0 0 0"
            p="5px"
            display="flex"
            justifyContent="center"
            color={
              status === 'Declined'
                ? colors.redAccent[500]
                : status === 'Completed'
                ? colors.greenAccent[500]
                : colors.blueAccent[700]
            }
            borderRadius="4px"
          >
            <PendingOutlinedIcon />
            <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
              {status}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: 'Actions',
      flex: 1,
      align: 'left',
      renderCell: (params) => {
        return (
          <Box
            m="0 0 0 0"
            p="5px 0"
            display="flex"
            justifyContent="space-between"
            borderRadius="4px"
          >
            <Link to={`/withdrawal-details/${params.row.txId}`} style={{ marginRight: '20px' }}>
              <IconButton>
                <RemoveRedEyeOutlined sx={{ color: `${colors.grey[100]} !important` }} />
              </IconButton>
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="WITHDRAWAL TRANSACTIONS"
          subtitle="List of All Withdrawal Requests By Users (Ordered By Recent)"
        />
      </Box>
      <Box
        m="30px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
            fontSize: '15px',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            margin: '0 0 10px 0',
          },
          '& MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {data && (
          <DataGrid
            checkboxSelection
            rows={data.sort((a, b) => b.id - a.id)}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(data) => {
              return data.txId;
            }}
          />
        )}
        {loading && <div style={{ color: 'red !important', fontSize: '30px' }}>Loading...</div>}
        {error && <div style={{ color: 'red !important', fontSize: '30px' }}>{error}</div>}
      </Box>
    </Box>
  );
};

export default Withdrawals;
